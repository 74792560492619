import { OKTA_ISSUER, OKTA_CLIENTID } from '../../config/app'

let globalObject = null

class Okta {
  constructor() {
    if (!globalObject) {
      globalObject = this
      this.authClient = null
      this.token = null
    }
    return globalObject
  }

  init() {
    if (!window.OktaAuth) {
      return
    }
    const config = {
      clientId: OKTA_CLIENTID,
      issuer: OKTA_ISSUER,
      redirectUri: '/user/callback',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      pkce: true,
    }
    globalObject.authClient = new window.OktaAuth(config)

    globalObject.authClient.tokenManager.on(
      'renewed',
      function (key, newToken) {
        globalObject.token = newToken.accessToken
      }
    )
  }

  async oktaLogoutMethod() {
    await globalObject.authClient.revokeAccessToken()
    globalObject.authClient.signOut()
  }

  loginMethod() {
    globalObject.authClient.token
      .getWithRedirect({
        responseType: ['token', 'id_token'],
        state: 'defaultrandomstring',
      })
      .catch(function (err) {
        console.error('Error OKTA login redirect', err)
      })
  }

  setTokenMethod(callback, errorHandler) {
    globalObject.authClient.token
      .parseFromUrl()
      .then(async function (res) {
        // manage token or tokens
        var tokens = res.tokens

        // Setting token in token manager to get fetch access token from SDK
        globalObject.authClient.tokenManager.setTokens(tokens)
        const token = await globalObject.authClient.tokenManager.getTokensSync()

        const { accessToken } = token
        const authToken = accessToken.accessToken
        globalObject.token = authToken
        callback()
      })
      .catch(function (err) {
        console.error('Error OKTA set token: ', err)
        errorHandler && errorHandler(err)
      })
  }

  subscribeTokenExpiryEventMethod() {
    globalObject.authClient.tokenManager.on('expired', function () {
      globalObject.authClient.tokenManager.renew('accessToken')
    })
  }

  async isOktaLoggedInMethod() {
    const token = await globalObject.authClient.tokenManager.get('accessToken')
    if (!token || globalObject.authClient.tokenManager.hasExpired(token)) {
      return false
    }
    globalObject.token = token.accessToken
    return true
  }

  fetchAuthTokenMethod() {
    return globalObject.token
  }
}

const oktaObject = new Okta()
oktaObject.init()

const oktaLogin = oktaObject.loginMethod

const setToken = oktaObject.setTokenMethod

const isOktaLoggedIn = oktaObject.isOktaLoggedInMethod

const subscribeTokenExpiryEvent = oktaObject.subscribeTokenExpiryEventMethod

const oktaLogout = oktaObject.oktaLogoutMethod

const fetchAuthToken = oktaObject.fetchAuthTokenMethod

export {
  oktaLogin,
  setToken,
  isOktaLoggedIn,
  subscribeTokenExpiryEvent,
  oktaLogout,
  fetchAuthToken,
}
