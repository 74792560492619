import React, { Component, useContext } from 'react'
import { Link } from 'react-router-dom'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import Loader from 'components/Loader'
import { Popup } from 'components/Popup'
import { getMessage } from 'lib/translator'
import { hasPermissions, isExtensionEnabled, getSession } from 'lib/auth'
import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import PackOrderForm from './PackOrder'
import DispatchOrderForm from './DispatchOrder'
import CompleteOrderForm from './CompleteOrder'
import CancelOrderForm from './CancelOrder'
import PrintOrderForm from './PrintOrder'
import AssignPickerForm from './AssignPicker'
import API from 'lib/api'
import { getEndpointToPrintInvoice } from 'lib/commonlyused'
import '../../../Orders/Details/style.css'
import './style.css'

const orderTypeMapping = {
  SALES_ORDER_TYPE_ONLINE_GROCERY: 'DELIVERY',
  SALES_ORDER_TYPE_BUSINESS_TO_BUSINESS: 'B2B',
  SALES_ORDER_TYPE_RB_PREORDER: 'RB_PREORDER',
  SALES_ORDER_TYPE_SCAN_AND_GO: 'OFFLINE',
}

export default function OrderActionsWrapper(props) {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <OrderActions splits={splits} {...props} />
}
class OrderActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      loading: false,
      failed: false,
      packOrderPopup: false,
      dispatchOrderPopup: false,
      completeOrderPopup: false,
      cancelOrderPopup: false,
      printOrderPopup: false,
      assignPickerOrderPopup: false,
      packlistData: this.props.packlistData,
      enableMoveCompletedToPacked: false
    }
    this.handlePopup = this.handlePopup.bind(this)
    this.showDropdown = this.showDropdown.bind(this)
    this.printInvoice = this.printInvoice.bind(this)
    this.statusCanPack = [
      'DELIVERY_ORDER_STATUS_PENDING',
      'DELIVERY_ORDER_STATUS_PROCESSING',
      'DELIVERY_ORDER_STATUS_PICKING',
      'DELIVERY_ORDER_STATUS_PICKED',
      'DELIVERY_ORDER_STATUS_PACKING',
      'DELIVERY_ORDER_STATUS_DISPATCHED',
      'DELIVERY_ORDER_STATUS_CANCELLED',
      'DELIVERY_ORDER_STATUS_COMPLETED'
    ]
    this.statusCanDispatch = ['DELIVERY_ORDER_STATUS_PACKED']
    this.statusCanComplete = ['DELIVERY_ORDER_STATUS_DISPATCHED']
    this.statusCannotCancel = [
      'DELIVERY_ORDER_STATUS_COMPLETED',
      'DELIVERY_ORDER_STATUS_CANCELLED',
    ]
    this.statusCanEditPacklist = [
      'DELIVERY_ORDER_STATUS_PROCESSING',
      'DELIVERY_ORDER_STATUS_PICKING',
      'DELIVERY_ORDER_STATUS_PACKING',
      'DELIVERY_ORDER_STATUS_PACKED',
    ]
    this.statusCanPrintOrder = [
      'DELIVERY_ORDER_STATUS_PENDING',
      'DELIVERY_ORDER_STATUS_PICKING',
      'DELIVERY_ORDER_STATUS_PICKED',
      'DELIVERY_ORDER_STATUS_PACKING',
      'DELIVERY_ORDER_STATUS_COMPLETED',
      'DELIVERY_ORDER_STATUS_DISPATCHED',
      'DELIVERY_ORDER_STATUS_PACKED',
      'DELIVERY_ORDER_STATUS_PROCESSING',
      'DELIVERY_ORDER_STATUS_PICKING',
      'DELIVERY_ORDER_STATUS_PACKING',
      'DELIVERY_ORDER_STATUS_PACKED',
    ]
    this.statusCanAsssignPicker = [
      'DELIVERY_ORDER_STATUS_PENDING',
      'DELIVERY_ORDER_STATUS_PROCESSING',
    ]
    this.statusCanReturnOrder = [
      'DELIVERY_ORDER_STATUS_PACKED',
      'DELIVERY_ORDER_STATUS_DISPATCHED',
      'DELIVERY_ORDER_STATUS_COMPLETED',
    ]
  }

  componentDidMount() {
    if (this.props.splits?.[SPLIT_FEATURES.MOVE_COMPLETED_ORDER_TO_PACKED]?.treatment === 'on') {
      const { user } = getSession();
      const flagConfig = JSON.parse(this.props.splits[SPLIT_FEATURES.MOVE_COMPLETED_ORDER_TO_PACKED].config);
      const userDesignationsFromFlag = 'userDesignations' in flagConfig ? JSON.parse(flagConfig?.userDesignations) : [];
      this.setState({ enableMoveCompletedToPacked: userDesignationsFromFlag.includes(user?.designation.name) })
    }
  }

  async printInvoice() {
    const { customer, status, store } = this.props.data

    // Until every order flow is using v3, some orders will be missing salesOrder
    // Below checking is temporary and it's to show error for such order that doesn't have salesOrder
    if (!this.props.salesOrderType) {
      this.setState({
        error: {
          message: 'Unable to retrieve invoice',
          details: 'Order is missing order type',
          status: true,
        },
      })
      return
    }

    if (status === 'DELIVERY_ORDER_STATUS_COMPLETED') {
      this.setState({ loading: true })
      const apiUrl = getEndpointToPrintInvoice({
        storeId: store.id,
        customerId: customer.id,
        type: orderTypeMapping[this.props.salesOrderType],
        referenceNumber: this.props.referenceNumber,
      })

      const invoiceServiceAPI = new API({ url: apiUrl })
      try {
        const byteArray = await invoiceServiceAPI.fileFetch()
        if (byteArray) {
          const blob = new Blob([byteArray], { type: 'application/pdf' })
          const blobURL = URL.createObjectURL(blob)
          window.open(blobURL)
        }
      } catch (err) {
        this.setState({
          error: {
            message: 'Invoice not ready',
            status: true,
            details:
              'Service is unable to generate invoice, check whether SAP invoice number is generated',
          },
        })
      } finally {
        this.setState({ loading: false })
      }
    } else {
      this.setState({
        error: {
          message: 'Order not completed',
          details: 'Wait for order to complete, before generating invoice',
          status: true,
        },
      })
    }
  }

  handlePopup(name) {
    const popupName = `${name}OrderPopup`
    const popupState = this.state[popupName]
    this.setState({
      [popupName]: !popupState,
    })
  }

  hasEditablePacklist(packlistData) {
    if (packlistData.length === 0) {
      return true
    }

    return (
      packlistData &&
      packlistData.some(
        (packlist) =>
          packlist.status === 'PACKLIST_STATUS_PACKED' ||
          packlist.status === 'PACKLIST_STATUS_RECEIVED'
      )
    )
  }

  hasPrintablePackageCodes(packlistData) {
    return (
      packlistData &&
      packlistData.length > 0 &&
      packlistData.flatMap((x) => x.packages).length > 0
    )
  }

  hasPickablePacklist(packlistData) {
    return (
      packlistData &&
      packlistData.length > 0 &&
      packlistData.some((x) => x.status === 'PACKLIST_STATUS_PENDING')
    )
  }

  showDropdown(
    canbePacked,
    canbeDispatched,
    canbeCompleted,
    canbeCancelled,
    canEditPacklist,
    canPrintOrder
  ) {
    return (
      canbePacked ||
      canbeDispatched ||
      canbeCompleted ||
      canbeCancelled ||
      canEditPacklist ||
      canPrintOrder
    )
  }

  canBeUpdated(loadingStatus, allowedStatuses, orderStatus, canEdit) {
    return loadingStatus && allowedStatuses.includes(orderStatus) && canEdit
  }

  render() {
    const { data, packlistData, referenceNumber } = this.props

    const hasOrderEditPermission = hasPermissions('order', 'order', 'put')
    const hasOrderPostPermissions = hasPermissions('order', 'order', 'post')

    const contentLoaded = !this.state.loading && !this.state.failed && data

    /* If order status is COMPLETED, check enableMoveCompletedToPacked by designation also */
    const hasOrderPackPermission = data.status === 'DELIVERY_ORDER_STATUS_COMPLETED' ?
      (hasOrderEditPermission && this.state.enableMoveCompletedToPacked) : hasOrderEditPermission;


    const canbePacked = this.canBeUpdated(
      contentLoaded,
      this.statusCanPack,
      data.status,
      hasOrderPackPermission
    )
    const canbeDispatched = this.canBeUpdated(
      contentLoaded,
      this.statusCanDispatch,
      data.status,
      hasOrderEditPermission
    )
    const canbeCompleted = this.canBeUpdated(
      contentLoaded,
      this.statusCanComplete,
      data.status,
      hasOrderEditPermission
    )
    const canbeCancelled = !this.canBeUpdated(
      contentLoaded,
      this.statusCannotCancel,
      data.status,
      hasOrderEditPermission
    )
    const canEditPacklist = this.canBeUpdated(
      contentLoaded,
      this.statusCanEditPacklist,
      data.status,
      hasOrderEditPermission
    )
    const canPrintOrder = this.canBeUpdated(
      contentLoaded,
      this.statusCanPrintOrder,
      data.status,
      hasOrderEditPermission
    )
    const canAssignPicker = this.canBeUpdated(
      contentLoaded,
      this.statusCanAsssignPicker,
      data.status,
      hasOrderEditPermission
    )
    const canReturnOrder =
      hasOrderPostPermissions &&
      isExtensionEnabled('OrderReturns') &&
      this.statusCanReturnOrder.includes(data.status)

    if (this.state.loading) {
      return (
        <div className="loader-bg">
          <Loader />
        </div>
      )
    }

    return (
      <div className="order-actions">
        <div className="order-actions-mobile-view">
          <DropDown
            className="order-actions-dropdown"
            icon={
              <img
                src={ICONS.VELLIP}
                alt="⋮"
                data-testid="order-status-actions-icon-mobile"
              />
            }
          >
            <DropDownItem>
              <button className="invoice-button">
                <Link to={`/order-log/${referenceNumber}`}>
                  {getMessage('order.actions.logs')}
                </Link>
              </button>
            </DropDownItem>
          </DropDown>
        </div>
        <div className="order-actions-web-view">
          <button
            className="invoice-button print-icon"
            onClick={this.printInvoice}
          >
            {getMessage('order.details.invoice.printButtonText')}
          </button>
          <button className="invoice-button">
            <Link to={`order-log/${referenceNumber}`}>
              {getMessage('order.actions.logs')}
            </Link>
          </button>
        </div>
        {/* Pack Order Popup */}
        <Popup
          heading={getMessage('order.action.packed')}
          show={this.state.packOrderPopup}
          close={() => this.handlePopup('pack')}
        >
          <PackOrderForm
            data={data}
            packlistData={packlistData}
            updateCompleted={this.props.reloadDetails}
            onCancel={() => this.handlePopup('pack')}
          />
        </Popup>
        {/* Dispatch Order Popup */}
        <Popup
          heading={getMessage('deliveryOrder.details.action.dispatchOrder')}
          show={this.state.dispatchOrderPopup}
          close={() => this.handlePopup('dispatch')}
        >
          <DispatchOrderForm
            data={data}
            updateCompleted={this.props.reloadDetails}
            onCancel={() => this.handlePopup('dispatch')}
          />
        </Popup>
        {/* Complete Order Popup */}
        <Popup
          heading={getMessage('deliveryOrder.details.action.completeOrder')}
          show={this.state.completeOrderPopup}
          close={() => this.handlePopup('complete')}
        >
          <CompleteOrderForm
            data={data}
            updateCompleted={this.props.reloadDetails}
            onCancel={() => this.handlePopup('complete')}
          />
        </Popup>
        {/* Cancel Order Popup */}
        <Popup
          heading={getMessage('deliveryOrder.details.action.cancelOrder')}
          show={this.state.cancelOrderPopup}
          close={() => this.handlePopup('cancel')}
        >
          <CancelOrderForm
            data={data}
            onCancel={() => this.handlePopup('cancel')}
            updateCompleted={this.props.reloadDetails}
          />
        </Popup>
        {/* Print Order Popup */}
        <Popup
          heading={getMessage('order.details.print.optionText')}
          show={this.state.printOrderPopup}
          close={() => this.handlePopup('print')}
          className="print-tote-label-modal"
        >
          <PrintOrderForm
            data={data}
            packlistData={packlistData}
            orderReferenceNumber={referenceNumber}
            onCancel={() => this.handlePopup('print')}
          />
        </Popup>
        {/* Assign Picker Popup */}
        <Popup
          heading={getMessage('deliveryOrder.details.action.assignPicker')}
          show={this.state.assignPickerOrderPopup}
          close={() => this.handlePopup('assignPicker')}
          className="assign-picker-modal"
        >
          <AssignPickerForm
            data={data}
            orderReferenceNumber={referenceNumber}
            onCancel={() => this.handlePopup('assignPicker')}
            updateCompleted={this.props.reloadDetails}
            packlistData={packlistData}
          />
        </Popup>
        {this.showDropdown(
          canbePacked,
          canbeDispatched,
          canbeCompleted,
          canbeCancelled,
          canEditPacklist,
          canPrintOrder
        ) && (
          <DropDown
            className="order-actions-dropdown"
            icon={
              <img
                src={ICONS.VELLIP}
                alt="⋮"
                data-testid="order-status-actions-icon-web"
              />
            }
          >
            {canbePacked && (
              <DropDownItem onClick={() => this.handlePopup('pack')}>
                {getMessage('order.action.packed')}
              </DropDownItem>
            )}
            {canbeDispatched && (
              <DropDownItem onClick={() => this.handlePopup('dispatch')}>
                {getMessage('deliveryOrder.details.action.dispatchOrder')}
              </DropDownItem>
            )}
            {canbeCompleted && (
              <DropDownItem onClick={() => this.handlePopup('complete')}>
                {getMessage('deliveryOrder.details.action.completeOrder')}
              </DropDownItem>
            )}
            {canbeCancelled && (
              <DropDownItem onClick={() => this.handlePopup('cancel')}>
                {getMessage('deliveryOrder.details.action.cancelOrder')}
              </DropDownItem>
            )}
            {canPrintOrder && this.hasPrintablePackageCodes(packlistData) && (
              <DropDownItem onClick={() => this.handlePopup('print')}>
                {getMessage('order.details.print.optionText')}
              </DropDownItem>
            )}
            {canEditPacklist && this.hasEditablePacklist(packlistData) && (
              <DropDownItem>
                <Link to={`edit-packed/${referenceNumber}`}>
                  {getMessage('order.action.editPackedOrder')}
                </Link>
              </DropDownItem>
            )}
            {canAssignPicker && this.hasPickablePacklist(packlistData) && (
              <DropDownItem onClick={() => this.handlePopup('assignPicker')}>
                {getMessage('deliveryOrder.details.action.assignPicker')}
              </DropDownItem>
            )}
            {canReturnOrder && (
              <DropDownItem>
                <Link to={`file-for-return/${referenceNumber}`}>
                  {getMessage('order.action.return')}
                </Link>
              </DropDownItem>
            )}
          </DropDown>
        )}
        {this.state.loading && (
          <div className="loader-bg">
            <Loader />
          </div>
        )}
        {this.state.failed ? (
          <div
            className="retry-message text-muted"
            onClick={() => this.props.reloadDetails()}
          >
            {getMessage('order.details.errors.onLoad')}
          </div>
        ) : null}
        {this.state.error && this.state.error.status && (
          <Popup
            show={this.state.error.status}
            referenceNumber={referenceNumber}
            close={() => this.setState({ error: null })}
            heading={`#${referenceNumber} ${this.state.error.message}`}
          >
            {this.state.error.details}
          </Popup>
        )}
      </div>
    )
  }
}
