import React from 'react'
import checkboxChecked from '../../../../icons/checkbox-checked.svg'
import checkboxUnchecked from '../../../../icons/checkbox-unchecked.svg'
import checkboxPartial from '../../../../icons/checkbox-partial.svg'
import expandedSelected from '../../../../icons/expanded-selected.svg'
import collapsedSelected from '../../../../icons/collapsed-selected.svg'
import expandedUnselected from '../../../../icons/expanded-unselected.svg'
import collapsedUnselected from '../../../../icons/collapsed-unselected.svg'
import { useConfirmationDialog } from './ConfirmationDialog'

const ActionsToolbar = ({
  selectedItems,
  items,
  tabName,
  handleDuplicate,
  handleReplicatetoO2O,
  view,
  setView,
  setSelectedItems,
  currentSubTab,
}) => {
  const handleSelectAll = () => {
    const allSelectedIds = new Set()

    items.forEach((item) => {
      allSelectedIds.add(item.id)
    })

    setSelectedItems(allSelectedIds)
  }

  const handleDeselectAll = () => {
    setSelectedItems(new Set())
  }

  return (
    <div className="actions-toolbar-container">
      <div
        className="swimlane-actions"
        style={{
          border: selectedItems.size === 0 ? 'none' : undefined,
        }}
      >
        {selectedItems.size === 0 && (
          <>
            <img
              src={checkboxUnchecked}
              role="button"
              alt="No Selected"
              aria-label="no-selected"
              onClick={handleSelectAll}
              className="mr-14"
            />
            <p onClick={handleSelectAll} style={{ margin: 0 }}>
              Select All
            </p>
          </>
        )}
        {selectedItems.size > 0 && selectedItems.size < items.length && (
          <img
            src={checkboxPartial}
            role="button"
            alt="Partial Selected"
            aria-label="partial-selected"
            className="mr-14"
            onClick={handleSelectAll}
          />
        )}
        {selectedItems.size === items.length && (
          <img
            src={checkboxChecked}
            role="button"
            alt="All Selected"
            aria-label="all-selected"
            className="mr-14"
            onClick={handleDeselectAll}
          />
        )}

        {selectedItems.size > 0 && (
          <>
            <div>
              <p className="selected-label mr-12">
                {selectedItems.size} selected
              </p>
            </div>
            <ActionWithConfirmation
              onConfirm={handleDuplicate}
              label="Duplicate"
              title="Duplicate"
              message="Duplicate the selected swimlanes?"
            />

            {tabName !== 'O2O' && currentSubTab === 'home' && (
              <ActionWithConfirmation
                onConfirm={handleReplicatetoO2O}
                label="Replicate to O2O"
                title="Replicate to O2O"
                message="Replicate the ENTIRE layout with selected swimlanes to O2O and create a new layout?"
              />
            )}
          </>
        )}
      </div>
      <div className="view-changer">
        <img
          src={view === 'collapsed' ? collapsedSelected : collapsedUnselected}
          role="button"
          alt=""
          aria-label={
            view === 'collapsed' ? 'expanded selected' : 'expanded unselected'
          }
          onClick={() => setView('collapsed')}
        />
        <img
          src={view === 'collapsed' ? expandedUnselected : expandedSelected}
          role="button"
          alt=""
          aria-label={
            view === 'collapsed' ? 'expanded unselected' : 'expanded selected'
          }
          onClick={() => setView('expanded')}
        />
      </div>
    </div>
  )
}

const ActionWithConfirmation = ({ onConfirm, label, title, message }) => {
  const { Dialog, showDialog } = useConfirmationDialog({
    title,
    message,
  })

  return (
    <>
      <Dialog onConfirm={onConfirm} />
      <button className="selected-action" onClick={showDialog}>
        {label}
      </button>
    </>
  )
}

export default ActionsToolbar
