import React from 'react'
import { BaseForm, Select, VALIDATION_TYPES } from 'components/Form'
import {
  ANALYTIC_SCHEMA,
  ANALYTIC_PLACEHOLDER,
  formatFormData,
  getConfig,
  getDataSource,
  IMG_PLACEHOLDER,
} from './utils'
import Loader from 'components/Loader'
import Recommender from './Recommender'
import { getMessage } from 'lib/translator'
import ModuleMetadata from './ModuleMetadata'
import { validateAnalytics } from '../utils'
import _cloneDeep from 'lodash/cloneDeep'
import Analytic from '../Analytic'
import _unset from 'lodash/unset'
import _set from 'lodash/set'
import _get from 'lodash/get'
import API from 'lib/api'
import './style.css'

class ModuleForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      submitAsDraft: false,
      errorFields: {},
      datasource: [],
      isLoading: false,
      values: {
        mConfig: {
          default: {},
        },
      },
    }

    this.getModuleType = this.getModuleType.bind(this)
    this.addUrlParams = this.addUrlParams.bind(this)
    this.deleteUrlParams = this.deleteUrlParams.bind(this)
    this.handleDatasource = this.handleDatasource.bind(this)
  }

  async componentDidMount() {
    let datasource
    const { activeModule, moduleValues, moduleType } = this.props
    const method = activeModule !== null ? 'edit' : 'add'
    if (method === 'edit') {
      const values = formatFormData(_cloneDeep(moduleValues)[activeModule])
      this.setState({ values, isLoading: true }, async () => {
        datasource = await getDataSource(values.mType)
        this.setState({ datasource, isLoading: false })
      })
    } else {
      datasource = await getDataSource(moduleType)
      this.setState({ datasource })
    }
  }

  onSubmit(_data) {
    const hasAnalytics = _data.mConfig.default.analytic?.content
    if (hasAnalytics) {
      const { isValid, parsedAnalytic, errors } = validateAnalytics(
        _data.mConfig.default.analytic.content,
        ANALYTIC_SCHEMA
      )
      if (isValid) {
        _set(_data, 'mConfig.default.analytic.content', parsedAnalytic)
      } else {
        this.setState({
          errorFields: { swimlane: errors[0].stack },
        })
        return
      }
    } else {
      _unset(_data, 'mConfig.default.analytic')
    }
    const {
      moduleValues,
      moduleType,
      activeModule,
      setCacheValues,
      setIsLoading,
      setPopUp,
      layout,
    } = this.props

    setPopUp(null)
    setIsLoading(true)

    let payload
    let apiUrl
    let method
    const { mName, mDataSource } = _data
    const sharedPayload = {
      mConfig: getConfig(_data),
      mIsEnable: !this.state.submitAsDraft,
    }
    const existingKey = Object.keys(moduleValues)[activeModule]

    if (existingKey) {
      payload = {
        mName,
        mDataSource,
        ...sharedPayload,
      }
      apiUrl = `/genie/lms/module/config?module_pk=${moduleValues[activeModule].id}`
      method = 'patch'
    } else {
      payload = {
        ..._data,
        omniProfile: layout,
        mType: moduleType,
        ...sharedPayload,
      }
      apiUrl = '/genie/lms/module'
      method = 'post'
    }
    new API({ url: apiUrl })[method](payload).then(() => {
      setCacheValues(null)
    })
  }

  getModuleType(id) {
    return id ? this.state.values.mType : this.props.moduleType
  }

  addUrlParams() {
    const values = Object.assign({}, this.state.values)
    const index = Object.values(
      values.mConfig.default.apiConfig.urlParams
    ).length
    values.mConfig.default.apiConfig.urlParams[index] = {
      id: crypto.randomUUID(),
    }
    this.setState({ values })
  }

  deleteUrlParams(id, index) {
    const values = Object.assign({}, this.state.values)
    const validations = Object.assign({}, this.state.validations)
    values.mConfig.default.apiConfig.urlParams = Object.values(
      values.mConfig.default.apiConfig.urlParams
    )
      .filter((param) => param.id !== id)
      .reduce((accumulator, item, idx) => {
        accumulator[idx] = item
        return accumulator
      }, {})
    validations.mConfig.default.apiConfig.urlParams =
      validations.mConfig.default.apiConfig.urlParams.filter(
        (_, idx) => idx !== index
      )

    this.setState({ values, validations })
  }

  handleDatasource(datasource, type) {
    const newValues = Object.assign({}, this.state.values)
    const validations = Object.assign({}, this.state.validations)
    const hasUrlParams = newValues.mConfig.default.apiConfig?.urlParams
    const hasAnalytics = newValues.mConfig.default.analytic?.content
    if (datasource === 'r4u' && !hasUrlParams) {
      this.setState({
        values: {
          ...newValues,
          mConfig: {
            default: {
              ...newValues.mConfig.default,
              apiConfig: {
                urlParams: {
                  0: { id: crypto.randomUUID() },
                },
              },
            },
          },
        },
      })
    }

    if (datasource !== 'r4u' && hasUrlParams) {
      delete newValues.mConfig.default.apiConfig
      delete validations.mConfig.default.apiConfig
      this.setState({ values: newValues, validations })
    }

    if (type === 'product-swimlane' && datasource !== 'r4u' && hasAnalytics) {
      delete newValues.mConfig.default.analytic
      this.setState({ values: newValues, validations })
    }
  }

  render() {
    const type = this.getModuleType(this.state.values.id)
    const { errorFields, isLoading, datasource, values } = this.state

    return isLoading ? (
      <Loader />
    ) : (
      <div className="module-form-section">
        <div className="module-placeholder mb-1">
          <img src={IMG_PLACEHOLDER[type]} alt="module image" />
        </div>
        <ModuleMetadata
          generateStateMappers={this.generateStateMappers}
          values={values}
        />
        {type !== 'placeholder' && (
          <div className="datasource-section">
            <Select
              name="Datasource"
              label={getMessage('app.layout.module.contentConfig')}
              placeholder={getMessage(
                'app.layout.module.contentConfig.placeholder'
              )}
              options={[...datasource]}
              {...this.generateStateMappers({
                stateKeys: ['mDataSource'],
                validationType: VALIDATION_TYPES.ONSUBMIT,
                loseEmphasisOnFill: true,
                onChange: (value) => {
                  this.handleDatasource(value, type)
                },
              })}
            />
          </div>
        )}
        {values.mDataSource === 'r4u' && (
          <Recommender
            addUrlParams={this.addUrlParams}
            deleteUrlParams={this.deleteUrlParams}
            urlParams={_get(values, 'mConfig.default.apiConfig.urlParams', {})}
            generateStateMappers={this.generateStateMappers}
          />
        )}
        {(values.mDataSource === 'r4u' || type === 'card-swimlane') && (
          <Analytic
            type="swimlane"
            errorFields={errorFields}
            dismissErrors={() => this.setState({ errorFields: {} })}
            placeholder={ANALYTIC_PLACEHOLDER}
            generateStateMappers={this.generateStateMappers}
            stateKeys={['mConfig', 'default', 'analytic', 'content']}
          />
        )}
        <div className="buttons-section">
          <button
            className="button"
            type="button"
            onClick={() => {
              this.props.setPopUp(null)
              this.props.setActiveModule(null)
            }}
          >
            {getMessage('apphome.cancel')}
          </button>
          {!values.id && (
            <button
              type="button"
              className="button"
              data-testid="draft-button"
              onClick={() => {
                this.setState({ submitAsDraft: true }, this._submitHandler)
              }}
            >
              {getMessage('apphome.saveasdraft')}
            </button>
          )}
          <button
            className="button primary"
            type="button"
            onClick={() => {
              this.setState({ submitAsDraft: false }, this._submitHandler)
            }}
          >
            {getMessage('apphome.enable')}
          </button>
        </div>
      </div>
    )
  }
}

export default ModuleForm
