import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Menu, { NavigationDrawer } from '../../components/Menu'
import { getMessage } from '../../lib/translator'
import ErrorBoundary, { notifyBugsnag } from '../../components/ErrorBoundary'
import {
  isExtensionEnabled,
  getStores,
  setStores,
  getSession,
} from '../../lib/auth'
import { set, get } from '../../lib/storage'
import API from '../../lib/api'
import { oktaLogin } from '../../lib/okta'
import { IS_OKTA_ENABLED } from '../../config/app'
import { checkforActionTrailErrors } from '../../lib/actiontrail/error-utils'
import { datadogRum } from '@datadog/browser-rum'

const getHttpStatusMessages = () => ({
  403: getMessage('error.server.403'),
})
export default class AuthenticatedPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      httpStatus: 200,
    }
    this.title = props.title
    this.handleError = this.handleError.bind(this)
  }
  UNSAFE_componentWillMount() {
    window.addEventListener('unhandledrejection', this.handleError)
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleError)
  }

  componentDidMount() {
    if (isExtensionEnabled('MultiStoreSupport')) {
      if (!getStores()) {
        const api = new API({ url: '/account-service/store' })
        api.get({ paginate: 'false' }).then((response) => {
          let stores = response.data.store.reverse()
          const user = getSession() && getSession().user

          if (user && user.stores && user.stores.length > 0) {
            stores = user.stores.reverse()
          }

          setStores(stores)

          const store = get('store') || stores[0].id

          set('store', store)

          this.setState({
            stores,
            storeId: store,
          })

          this.props.setApiParam && this.props.setApiParam(store)
        })
      } else {
        this.setState({
          stores: getStores(),
        })
      }
    } else {
      if (!get('store')) {
        const orgId = getSession().organization && getSession().organization.id
        if (orgId) {
          const api = new API({ url: `/account-service/organization/${orgId}` })
          api.get().then((response) => {
            const storeId =
              response.data.organization.defaultStore &&
              response.data.organization.defaultStore.id
            const stores = [response.data.organization.defaultStore]
            set('store', storeId)
            setStores(stores)
            this.setState({
              storeId: storeId,
              stores,
            })
          })
        }
      } else {
        this.setState({
          stores: getStores(),
          storeId: get('store'),
        })
      }
    }
  }

  handleError(e) {
    e.preventDefault()

    const isActionTrailPOSTError = checkforActionTrailErrors(e, 'POST')

    if (isActionTrailPOSTError) {
      return
    }
    datadogRum.addError(e)
    const newState = Object.assign({}, this.state)
    if (checkforActionTrailErrors(e, 'GET') || e.reason.code === 401) {
      if (IS_OKTA_ENABLED) {
        oktaLogin()
      }

      newState.redirectToLogin = true
    } else if (e.reason.message === 'cancelled') {
      newState.error = ''
      newState.httpStatus = 200
      this.setState(newState)
    } else {
      newState.httpStatus = e.reason.code
      newState.error = e.reason.message
      if (e.reason.code !== 401 && e.reason.code !== 403) {
        notifyBugsnag(e.reason.message || e, e.reason)
      }
    }
    console.error(e.reason.message)
    if (e.reason.endpoint !== 'GEOCODER_GEOCODE') {
      this.setState(newState)
    }
  }

  updateStore(storeId) {
    this.setState(
      {
        storeId: storeId,
      },
      () => this.props.onChange && this.props.onChange(storeId)
    )
  }

  render() {
    const { props } = this
    const { from: pathname } = props
    const menuProps = {
      items: props.menu,
    }
    if (props.storeDependent && isExtensionEnabled('MultiStoreSupport')) {
      menuProps.stores = this.state.stores
      menuProps.storeId = this.state.storeId
    }
    if (props.showLanguageDropDown) {
      menuProps.showLanguageDropDown = true
    }

    return this.state.redirectToLogin ? (
      <Redirect
        to={{
          pathname: '/user/logout',
          state: { from: pathname ? { pathname } : undefined },
        }}
      />
    ) : (
      <div id="app">
        {props.menu ? (
          <Menu {...menuProps} onChange={(e) => this.updateStore(e)} />
        ) : null}
        <main className={props.className}>
          {props.menu ? <NavigationDrawer /> : null}
          <ErrorBoundary title={this.title} showHeader={false}>
            {this.state.error ? (
              <div>
                <h1>{this.title}</h1>
                {getHttpStatusMessages()[this.state.httpStatus] ||
                  getMessage('error.server')}
              </div>
            ) : (
              props.children
            )}
          </ErrorBoundary>
        </main>
      </div>
    )
  }
}
