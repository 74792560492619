import toast from 'react-hot-toast'
import { useRef, useEffect } from 'react'
import QRCode from 'qrcode'
import {
  DELIVERY_ORDER_STATUSES,
  PAYMENT_STATUSES,
  PREORDER_STATUSES,
} from './constant'
import isEmpty from 'lodash.isempty'
import API from 'lib/api'
import groupBy from 'lodash/groupBy'
import lodashGet from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { MD5 } from 'crypto-js'
import { get } from 'lib/storage'

export function removeSku(obj, sku) {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== sku))
}

export function verifyCheckSum(barcode) {
  const checkSum = parseInt(barcode.charAt(barcode.length - 1), 10)
  let sum = 0
  let shouldMultiply = true

  for (let pos = barcode.length - 2; pos >= 0; pos--) {
    let digit = parseInt(barcode.charAt(pos), 10)
    if (shouldMultiply) {
      digit *= 3
    }
    shouldMultiply = !shouldMultiply
    sum += digit
  }

  return (sum + checkSum) % 10 === 0
}

export function processBarcode(barcode) {
  if (!verifyCheckSum(barcode)) {
    return barcode
  }

  const extractEANComponent = (bc, start, end, prefix) => {
    const EANComponent = bc.substring(start, end)
    return `${prefix}${EANComponent}`
  }

  const patterns = [
    { regex: /^25[0-9]{16}$/, start: 2, end: 8, prefix: '250000' },
    { regex: /^22[0-9]{6,16}$/, start: 2, end: 7, prefix: '2200000' },
    { regex: /^21[0-9]{11}$/, start: 2, end: 8, prefix: '210000' },
  ]

  for (const pattern of patterns) {
    if (pattern.regex.test(barcode)) {
      return extractEANComponent(
        barcode,
        pattern.start,
        pattern.end,
        pattern.prefix
      )
    }
  }

  return barcode // In case no patterns match
}

export function getPreOrderStatus(data) {
  const paymentStatus = data.payments[0].status
  const orderStatus = data.status

  if (paymentStatus === PAYMENT_STATUSES.PAYMENT_STATUS_COMPLETED) {
    if (
      orderStatus === DELIVERY_ORDER_STATUSES.DELIVERY_ORDER_STATUS_DISPATCHED
    ) {
      return PREORDER_STATUSES.READY_FOR_COLLECTION
    } else if (
      orderStatus === DELIVERY_ORDER_STATUSES.DELIVERY_ORDER_STATUS_CANCELLED
    ) {
      return PREORDER_STATUSES.CANCELLED
    } else if (
      orderStatus === DELIVERY_ORDER_STATUSES.DELIVERY_ORDER_STATUS_COMPLETED
    ) {
      return PREORDER_STATUSES.COLLECTED
    }

    return PREORDER_STATUSES.PENDING_DISPATCH
  } else if (paymentStatus === PAYMENT_STATUSES.PAYMENT_STATUS_CANCELLED) {
    return PREORDER_STATUSES.CANCELLED
  } else {
    if (
      orderStatus === DELIVERY_ORDER_STATUSES.DELIVERY_ORDER_STATUS_CANCELLED
    ) {
      return PREORDER_STATUSES.CANCELLED
    }
    return PREORDER_STATUSES.PENDING_PAYMENT
  }
}
export function isOfflinePreorder(data) {
  if (!data) {
    return false
  }
  return data.payments[0].method === 'OFFLINE'
}

export function getOrderSummaryFromOrderListItem(data) {
  let orderStatus

  if (data.paymentStatus === 'COMPLETED') {
    switch (data.status) {
      case 'CANCELLED':
        orderStatus = PREORDER_STATUSES.CANCELLED
        break
      case 'COMPLETED':
        orderStatus = PREORDER_STATUSES.COLLECTED
        break
      case 'DISPATCHED':
        orderStatus = PREORDER_STATUSES.READY_FOR_COLLECTION
        break
      case 'PENDING' | 'PACK':
        orderStatus = PREORDER_STATUSES.PENDING_DISPATCH
        break
      default:
        orderStatus = PREORDER_STATUSES.PENDING_DISPATCH
        break
    }
  } else {
    if (data.status === 'CANCELLED') {
      orderStatus = PREORDER_STATUSES.CANCELLED
    } else {
      orderStatus = PREORDER_STATUSES.PENDING_PAYMENT
    }
  }

  return {
    id: data.referenceNumber,
    total: data.payments[0].amount.toFixed(2),
    storeName: data.store.name,
    pickupTime: formatDateTimeRange(
      `${data.preferredDate} ${data.slotStartTime}`,
      `${data.preferredDate} ${data.slotEndTime}`
    ),
    customerName: data.customer.name,
    customerPhone: data.customer.phone.replace('+65', ''),
    status: orderStatus,
  }
}

export function getOrderSummary(data) {
  const status = getPreOrderStatus(data)

  const customer = data.customer
  const store = data.store

  return {
    id: data.referenceNumber,
    total: data.payments[0].amount.toFixed(2),
    storeName: store.name,
    pickupTime: formatDateTimeRange(data.slotStartTime, data.slotEndTime),
    customerName: customer.name,
    customerPhone: customer.phone.replace('+65', ''),
    status: status,
  }
}

export function formatDateTimeRange(start, end) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  function formatTime(time) {
    const hours = time.getHours()
    const period = hours >= 12 ? 'pm' : 'am'
    const hour12 = hours % 12 || 12
    return `${hour12}${period}`
  }

  function formatDate(date) {
    const day = date.getDate()
    const month = months[date.getMonth()]
    const year = date.getFullYear()
    return `${day} ${month} ${year}`
  }

  const startDate = new Date(start)
  const endDate = new Date(end)

  const startTime = formatTime(startDate)
  const endTime = formatTime(endDate)
  const formattedDate = formatDate(startDate)

  return `${startTime}–${endTime}, ${formattedDate}`
}

async function decryptAesCbc(encryptedBase64, secretKey, ivBase) {
  // Convert base64 strings to ArrayBuffer
  const encryptedData = base64ToArrayBuffer(encryptedBase64)
  const keyData = base64ToArrayBuffer(secretKey)
  const iv = base64ToArrayBuffer(ivBase)

  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'AES-CBC' },
    false,
    ['decrypt']
  )

  const decryptedData = await window.crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: iv },
    cryptoKey,
    encryptedData
  )
  return new TextDecoder().decode(decryptedData)
}

// Helper function to convert base64 to ArrayBuffer
function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

// Example usage
const preorderDeeplinkKey = process.env.REACT_APP_PREORDER_DEEPLINK_KEY || ''
const [keyBase64, ivBase64] = preorderDeeplinkKey.split(',')

const isPreorderOfflineQR = (text) => {
  return text.includes('PLU') && text.includes('ID:')
}

export const extractOrderIDFromQR = async (decodedText) => {
  try {
    if (decodedText) {
      const preorderOfflineOrderId = isPreorderOfflineQR(decodedText)
        ? Number(decodedText.split('PLU')[0].replace('ID:', ''))
        : NaN

      if (!isNaN(preorderOfflineOrderId)) {
        return preorderOfflineOrderId
      }

      if (decodedText.length < 40) {
        return ''
      }

      const deepLink = await decryptAesCbc(
        decodedText,
        keyBase64,
        ivBase64
      ).catch((e) => {
        console.log(e)
        return ''
      })

      return deepLink && deepLink.includes('fairprice://rbpreorder?ref=')
        ? deepLink.split('ref=')[1]
        : ''
    }
    return ''
  } catch (err) {
    console.log(err)
    return ''
  }
}

// slim down product data as it is too much
export const getSlimProductData = (product) => ({
  // for UI
  name: product.name,
  sku: product.clientItemId,
  price:
    product.storeSpecificData[0].mrp - product.storeSpecificData[0].discount,
  // for checkout
  id: product.id, // DBP product ID
})

// slim down cart product data as it is too much
export const getSlimCartProductData = (item) => ({
  // for UI
  name: item.product.name,
  sku: item.product.clientItemId,
  price: item.mrp - item.discount,
  isFree: item.isFree,
  // for checkout
  id: item.product.id, // DBP product ID
})

// slim down category data as it is too much
export const getCategoryNameAndSlug = (category) => ({
  id: category.id,
  name: category.name,
  slug: category.slug,
})

// slim down campaign data as it is too much
export const getSlimCampaignData = (campaign) => ({
  // for UI dropdown
  text: campaign.name,
  value: campaign.id,
  // for API
  id: campaign.id,
  tag_id: campaign.tag_id, // to get campaign slug
  category_id: campaign.category_id, // to get sub-categories
  hasSkuScanner: campaign.has_sku_scanner,
})

export const isProductAdded = (products, scannedSku) => {
  if (isEmpty(products)) {
    return false
  }
  return products[scannedSku] !== undefined
}

export const errorToast = (id, errorMessage) => {
  toast.error(errorMessage, {
    style: {
      border: '1px solid rgba(193, 11, 58, 1)',
      padding: '16px',
      color: 'rgba(193, 11, 58, 1)',
    },
    iconTheme: {
      primary: 'rgba(193, 11, 58, 1)',
      secondary: '#ffffff',
    },
    id,
  })
}

export const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const xUserHeaders = ({ userId, userUid }) => ({
  'X-User-Source': 'dbp',
  'X-User-Id': userId,
  'X-Customer-Id': userUid,
})
export const refreshedOrderDetail = (orderId) => {
  return new API({
    url: `/order-service/v3/deliveryOrders/${orderId}`,
  }).get()
}

export const handleMarkOrderResponse = (order, setFieldValue, toggleModal) => {
  setFieldValue('orderDetailData', order)
  toggleModal()
}

export const isGracePeriodOver = (gracePeriod, collectionDateTime, today) => {
  const collectionDate = new Date(collectionDateTime)

  const gracePeriodDate = new Date(collectionDate)
  gracePeriodDate.setDate(collectionDate.getDate() - gracePeriod)

  const strippedToday = new Date(today.setHours(0, 0, 0, 0))
  const strippedGracePeriodDate = new Date(gracePeriodDate.setHours(0, 0, 0, 0))

  return strippedToday > strippedGracePeriodDate
}

// re-group split SKU quantities
// because both /checkout and /order-service/v3/deliveryOrders
// API returns split SKU quantities
// input (pseudo code): [{sku: 1, q: 18}, {sku: 1, q: 2}, {sku: 2, q: 1}]
// output (pseudo code): [{sku: 1, q: 20}, {sku: 2, q: 1}]
export const groupCart = (
  cartItems,
  itemSkuKey = 'product.clientItemId', // group item based on SKU
  quantityKey = 'q'
) => {
  const cartItemsGroupedBySku = groupBy(cartItems, itemSkuKey)
  const addedFlag = {}
  return cartItems.reduce((result, item) => {
    const itemId = lodashGet(item, itemSkuKey)
    if (addedFlag[itemId]) {
      // skip item if it's already added
      return result
    }
    // group and return grouped quantity
    if (cartItemsGroupedBySku[itemId].length > 1) {
      const groupedQuantity = cartItemsGroupedBySku[itemId].reduce(
        (t, cartItem) => t + Number(cartItem[quantityKey]),
        0
      )
      const newItem = cloneDeep(cartItemsGroupedBySku[itemId][0])
      newItem[quantityKey] = groupedQuantity.toString()
      addedFlag[itemId] = true
      result.push(newItem)
      return result
    }
    result.push(cartItemsGroupedBySku[itemId][0])
    return result
  }, [])
}

const PIXEL_TO_MM = 0.26
export const htmlToPreorderPdf = async ({ input, height, orderId }) => {
  const pdf = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: [100, height * PIXEL_TO_MM],
    compress: true,
  })
  html2canvas(input)
    ?.then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 2
      const imgY = 10
      pdf.addImage(
        imgData,
        'JPEG',
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio,
        '',
        'NONE'
      )
      pdf.save(`FPG_${orderId}.pdf`)
    })
    .catch()
}

export const generateQRFromPLU = async (orderId, allPLUstring) => {
  const numOfQR = Math.ceil(allPLUstring.length / 30)
  return Promise.all(
    Array(numOfQR)
      .fill(1)
      .map((_, i) => {
        const processedPLU = allPLUstring.slice(i * 30, i * 30 + 30).join('/')
        const md5Hash = MD5(processedPLU)
        return QRCode.toDataURL(`ID:${orderId}\nPLU:${processedPLU}/${md5Hash}`)
      })
  )
}

export const parseStoreMetaData = (storeId, metaDataResponse) => {
  const storeDetail = JSON.parse(get('stores')).find(
    (store) => Number(store.id) === Number(storeId)
  )
  return Object.keys(storeDetail.metaData).reduce((obj, id) => {
    const foundedMetadata = Object.keys(metaDataResponse || {}).find(
      (key) => metaDataResponse[key].id === Number(id)
    )
    obj[foundedMetadata] = storeDetail.metaData[id]
    return obj
  }, {})
}

export const createConsentConfirmHandler =
  (canvasRef, onConfirm) => async () => {
    if (canvasRef.current) {
      const base64Signature = await canvasRef.current.toDataURL('jpg')
      onConfirm(base64Signature)
      return true
    }
    return false
  }
export const createClearCanvasHandler = (canvasRef, callback) => () => {
  if (canvasRef.current) {
    canvasRef.current.clear()
    callback()
  }
}

export const renderBorderColor = (error, isFocus) => {
  return error
    ? '1px solid red'
    : isFocus
      ? '1px solid #1454B8'
      : '1px solid #EAEAEA'
}
