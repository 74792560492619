import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
// import { unregister } from './registerServiceWorker'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './containers/App/App'
import {
  GTM_ID,
  RUM_APPLICATION_ID,
  RUM_CLIENT_TOKEN,
  ENVIRONMENT,
  RELEASE_VERSION,
  DD_SERVICE_NAME,
} from './config/app'

// Google Tag Manager
TagManager.initialize({
  gtmId: GTM_ID,
})

datadogRum.init({
  applicationId: RUM_APPLICATION_ID,
  clientToken: RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: DD_SERVICE_NAME,
  datacenter: 'us',
  sampleRate: 100,
  trackInteractions: true,
  env: ENVIRONMENT,
  version: RELEASE_VERSION,
  sessionReplaySampleRate: 0,
  tracingSampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  trackFrustrations: true,
  enableExperimentalFeatures: ['clickmap'],
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    // 'https://website-api.zs-uat.fairprice.com.sg/api',
    // 'https://api.zs-uat.fairprice.com.sg',
    // 'https://api.omni.fairprice.com.sg',
    // 'https://website-api.omni.fairprice.com.sg/api',
  ],
})

datadogRum.startSessionReplayRecording()

datadogLogs.init({
  applicationId: RUM_APPLICATION_ID,
  clientToken: RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  version: RELEASE_VERSION,
  env: ENVIRONMENT,
  service: DD_SERVICE_NAME,
  forwardErrorsToLogs: true,

  beforeSend: (log) => {
    /**
     * Implementation referenced from sentry to datadog migration from Loyalty tribe.
     * https://ntuc.atlassian.net/wiki/spaces/LP/pages/2458386453/SSO+Datadog+RUM#1.-Source-Errors as pointed
     * in this document and dev implementation it points at https://github.com/DataDog/browser-sdk/issues/1202 issue
     * associated with SDK which suggest to exclude console logs we have to do it by checking console tyoe as origin.
     * We are doing this to limit amout of errors that will be pushed to datadog
     */

    // datadog not collect console logs by default
    // if (event.error?.origin === 'console') {
    //   return false
    // }

    // discard network errors from external resources
    if (log.origin === 'network' && log.status === 'error' && log.http?.url) {
      const ignoredNetworkErrorHosts = [
        'auth.split.io',
        'sdk.split.io',
        'events.split.io',
      ]

      const canLogBeIgnored = ignoredNetworkErrorHosts.some(
        (ignoredNetworkErrorHost) =>
          log.http.url.includes(ignoredNetworkErrorHost)
      )

      if (canLogBeIgnored) {
        return false
      }
    }
    return true
  },

  // Do not send Datadog any telemetry.
  telemetrySampleRate: 0,

  // Need this so Datadog won't complain when Next.js hot-reloads during development.
  silentMultipleInit: true,
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Router>
    <App />
  </Router>
)
// registerServiceWorker() // TODO: Implement service worker to enable offline experience
// unregister()
