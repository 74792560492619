import React, { useEffect, useState } from 'react'
import './ConfirmationDialog.css'
import ReactDOM from 'react-dom'
import API from '../../../../lib/api'
import moment from 'moment'

const ConfirmationDialog = ({ onConfirm, onCancel, title, message }) => {
  const [o2OTimelines, setO2OTimelines] = useState([])
  const [timeLine, setTimeLine] = useState('default')

  useEffect(() => {
    const page = 'home'
    const url = `/website-service/layout/${page}/date`
    const params = {
      platform: 'o2o',
    }
    const api = new API({ url })

    api
      .get(params)
      .then((resp) => {
        let timeLines = resp.data.date
        timeLines = timeLines
          .filter((time) => time.validTill && time.validFrom)
          .map((time) => {
            const startDatetime = moment(time.validFrom)
            const endDatetime = moment(time.validTill)
            return {
              value: `${time.validFrom}/${time.validTill}`,
              text: `${startDatetime.format('Do MMM')} ${startDatetime.format(
                'hh:mm A'
              )} ${' - '} ${endDatetime.format('Do MMM')} ${endDatetime.format(
                'hh:mm A'
              )}`,
            }
          })

        timeLines = [{ text: 'Default', value: 'default' }, ...timeLines]

        setO2OTimelines(timeLines)
      })
      .catch((error) => {
        console.error(`Unable to fetch timeline dates: ${error.message}`)
      })
  }, [])

  const [status, setStatus] = useState('DISABLED')

  const handleOptionChange = (changeEvent) => {
    setStatus(changeEvent.target.value)
  }

  const handleConfirm = () => {
    onConfirm({ status, timeLine })
  }

  return (
    <div className="confimation-dialog backMask" onClick={onCancel}>
      <div className="dialog" onClick={(e) => e.stopPropagation()}>
        <div className="header">{title}</div>
        <div className="content">{message}</div>
        {title === 'Duplicate' && (
          // Need a better way to inject extra components
          <form className="radio-buttons-container">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="DISABLED"
                  checked={status === 'DISABLED'}
                  onChange={handleOptionChange}
                />
                Set as disabled
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="ENABLED"
                  checked={status === 'ENABLED'}
                  onChange={handleOptionChange}
                />
                Set as active
              </label>
            </div>
          </form>
        )}
        {title === 'Replicate to O2O' ? (
          o2OTimelines.length > 0 ? (
            <div style={{ padding: '1rem' }}>
              <label htmlFor="select-o2o-timeline">Select Timeline</label>
              <select
                id="select-o2o-timeline"
                role="combobox"
                placeholder="Select O2O Timeline"
                value={timeLine}
                onChange={(e) => {
                  setTimeLine(e.target.value)
                }}
              >
                {o2OTimelines.map((t) => {
                  return (
                    <option key={t.i} value={t.value}>
                      {t.text}
                    </option>
                  )
                })}
              </select>
            </div>
          ) : (
            <div>Loading O2O timeline</div>
          )
        ) : null}
        <div className="actions">
          <button type="button" className="button" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="button"
            className="button primary"
            onClick={handleConfirm}
          >
            Confirm and Save
          </button>
        </div>
      </div>
    </div>
  )
}

export const useConfirmationDialog = ({ title, message }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false)

  const Dialog = ({ onConfirm }) =>
    isDialogVisible &&
    ReactDOM.createPortal(
      <ConfirmationDialog
        title={title}
        message={message}
        onCancel={() => setIsDialogVisible(!isDialogVisible)}
        onConfirm={(value) => {
          onConfirm(value)
          setIsDialogVisible(!isDialogVisible)
        }}
      />,
      document.getElementById('portal')
    )

  return {
    Dialog,
    showDialog: () => setIsDialogVisible(true),
  }
}

export default React.memo(ConfirmationDialog)
