import React from 'react'
import ProductCollection from '../../../../components/Layout/ProductCollection'
import {
  Input,
  Select,
  CheckboxGroup,
  Radio,
  CategorySearch,
  BrandSearch,
  Searchable,
} from '../../../../components/Form'
import { isExtensionEnabled } from '../../../../lib/auth'
import { getMessage } from '../../../../lib/translator'
import SpecificProductDetails from '../components/SpecificProductDetails'
const ProductCollectionLayout = () => {
  return {
    preview: ProductCollection,
    fields: ({ getState, updateState, page, stores = [] }) => {
      const showOnly = [
        {
          text: getMessage('themes.offers'),
          value: 'hasOffers',
        },
        {
          text: getMessage('themes.images'),
          value: 'hasImage',
        },
        {
          text: getMessage('themes.stock'),
          value: 'hasStock',
        },
      ]
      const showOnlyValue = []

      showOnly.forEach((option) => {
        if (getState([option.value]) === 1) {
          showOnlyValue.push(option.value)
        }
      })

      return (
        <div>
          <div className="form-sections">
            <div className="form-section">
              <Input
                label={getMessage('themes.title')}
                placeholder="Type the collection title"
                value={getState(['title']) || ''}
                onChange={(e) => {
                  updateState(['title'], e)
                }}
              />
            </div>
            <div className="form-section">
              <Input
                label={getMessage('themes.cc.subtitle')}
                placeholder="Enter the  subtitle"
                value={getState(['subtitle']) || ''}
                onChange={(e) => {
                  updateState(['subtitle'], e)
                }}
              />
            </div>
            {isExtensionEnabled('ProductTagSupport') && (
              <div className="form-section">
                <Searchable
                  label="Tags"
                  placeholder={getMessage('themes.tag.show')}
                  name="tag"
                  searchUrl="/catalogue-service/tag"
                  valueKey="id"
                  nameKey="name"
                  searchKey="name"
                  expandMore={true}
                  transformResponse={(response) =>
                    (page === 'tag'
                      ? [
                          {
                            id: new Date().getTime(),
                            name: 'current',
                            slug: 'CURRENT',
                          },
                        ]
                      : []
                    ).concat(response.data.tag)
                  }
                  value={getState(['tag']) || updateState(['tag'], 'ALL')}
                  onChange={(value) => {
                    updateState(['tag'], value)
                  }}
                />
              </div>
            )}
            <div className="form-section">
              <CategorySearch
                label="Categories"
                appendElement={
                  page === 'category'
                    ? [
                        {
                          id: new Date().getTime(),
                          name: 'current',
                          displayName: 'current',
                          slug: 'CURRENT',
                        },
                      ]
                    : null
                }
                placeholder={getMessage('themes.category.show')}
                name="category"
                value={
                  getState(['category']) || updateState(['category'], 'ALL')
                }
                onChange={(value) => {
                  updateState(['category'], value)
                }}
              />
            </div>
          </div>
          <div className="form-sections">
            {isExtensionEnabled('MultiBrandSupport') && (
              <div className="form-section">
                <BrandSearch
                  label="Brands"
                  appendElement={
                    page === 'brand'
                      ? [
                          {
                            id: new Date().getTime(),
                            name: 'current',
                            displayName: 'current',
                            slug: 'CURRENT',
                          },
                        ]
                      : null
                  }
                  placeholder={getMessage('themes.brand.show')}
                  name="brand"
                  value={getState(['brand']) || updateState(['brand'], 'ALL')}
                  onChange={(value) => {
                    updateState(['brand'], value)
                  }}
                />
              </div>
            )}
            <div className="form-section">
              <Select
                label="Sort by"
                placeholder={getMessage('themes.sortinglogic')}
                options={[
                  {
                    text: 'Popularity',
                    value: 'POPULARITY',
                  },
                  {
                    text: 'New First',
                    value: 'NEW_FIRST',
                  },
                  {
                    text: 'New Last',
                    value: 'NEW_LAST',
                  },
                ]}
                value={getState(['sorting']) || ''}
                onChange={(value) => {
                  updateState(['sorting'], value)
                }}
              />
            </div>
          </div>
          <SpecificProductDetails
            getState={getState}
            updateState={updateState}
            stores={stores}
          />

          <div className="form-sections">
            <div className="form-section">
              <CheckboxGroup
                label={getMessage('themes.product')}
                options={showOnly}
                value={showOnlyValue}
                onChange={(values) => {
                  const irrellevant = showOnlyValue.filter(
                    (option) => values.indexOf(option) < 0
                  )
                  const yes = showOnlyValue.filter(
                    (option) => values.indexOf(option) >= 0
                  )
                  irrellevant.forEach((option) => {
                    updateState([option], '')
                  })
                  yes.forEach((option) => {
                    updateState([option], 1)
                  })
                }}
              />
            </div>
            <div className="form-section">
              <Radio
                name="layoutType"
                label={getMessage('themes.layoutType')}
                options={[
                  {
                    text: getMessage('themes.grid'),
                    value: 'GRID',
                  },
                  {
                    text: getMessage('themes.scroller'),
                    value: 'SCROLLER',
                  },
                ]}
                value={getState(['layoutType'])}
                onChange={(e) => {
                  if(e === 'SCROLLER'){updateState(['loadMoreType'], 'SEEALL')}
                  return updateState(['layoutType'], e)}}
              />
            </div>
            <div className="form-section">
              <Radio
                name="pagination"
                label={getMessage('themes.loadMoreType')}
                options={[
                  {
                    text: getMessage('themes.infiniteScroll'),
                    value: 'INFINITE',
                  },
                  {
                    text: getMessage('themes.seeAll'),
                    value: 'SEEALL',
                  },
                ]}
                value={getState(['loadMoreType'])}
                onChange={(e) => {
                  if(e === 'INFINITE'){updateState(['layoutType'], 'GRID')}
                  return updateState(['loadMoreType'], e)}
                }
              />
            </div>
            {getState(['personalisationApi']) ? (
              <div className="form-section">
                <Input
                  label={getMessage('themes.api')}
                  placeholder="Type the collection title"
                  value={getState(['personalisationApi'])}
                />
              </div>
            ) : null}
          </div>
        </div>
      )
    },
  }
}

export default ProductCollectionLayout
