import React from 'react'
import { BaseForm, Select } from 'components/Form'
import { ENVIRONMENT } from 'config/app'
import Loader from 'components/Loader'
import { getMessage } from 'lib/translator'
import API from 'lib/api';
import _ from 'lodash'
import './style.scss'

class AssignPicker extends BaseForm {
  constructor(props) {
    super(props);
    this.state = {
      selectedPicker: null,
      loading: false,
      availablePickers: null,
      isVerified: false,
      isOnlyMkpPacklists: false
    }
    this.handleSelectPicker = this.handleSelectPicker.bind(this);
  }

  componentDidMount() {
    if(this.props.packlistData) {
      this.checkForOnlyMkpPacklists();
    }
  }

  checkForOnlyMkpPacklists() {
    const { packlistData } = this.props;
    const mkpPacklists = packlistData.flatMap(x => x.ownerType).filter(x => x === "PACKLIST_OWNER_TYPE_VENDOR");
    // If both array lengths are the same, all are MKP packlists. Then, show error message and do not retrieve picker data
    if (mkpPacklists.length === packlistData.length) {
      this.setState({ isOnlyMkpPacklists: true });
    } else {
      this.fetchActivePickers();
    }
  }

  async fetchActivePickers() {
    this.setState({ loading: true });
    /* Fetch all pickers */
    const employeeAPI = new API({ url: '/account-service/employee' });
    const roleId = ENVIRONMENT === 'UAT' ? '293' : '175';
    const storeId = this.props.data.store.id;
    const response = await employeeAPI.get(
      { roleId: roleId, paginated: 'false', storeId: storeId },
    )
    const allPickers = response.data.employee.map(picker => ({ value: picker.id, text: picker.name }));

    /* Fetch active pickers */
    const activePickersAPI = new API({ url: `/picking-service/v3/pickers/${storeId}` });
    const activePickers = await activePickersAPI.get();

    /* Get available pickers from all pickers and active pickers */
    const availablePickers = this.getAvailablePickers(allPickers, activePickers.activePickers);

    /* remove duplicates and order the list by picker name */
    const filteredList = _.uniqBy(availablePickers, 'value');
    const orderedList = _.orderBy(filteredList, [picker => picker.text.toLowerCase()], ['asc']);
    this.setState({ availablePickers: orderedList, loading: false });
  }

  async verifyPickerByTag(pickerId) {
    const pickerzoneAPI = new API({ url: `/picking-service/v3/pickerZones`});
    const result = await pickerzoneAPI.get({ storeId: this.props.data.store.id, pickerId: pickerId });
    return result?.zones.some(x => x.tag === 'others'); // return true or false. return true as soon as the value is found
  }

  async handleSelectPicker(value) {
    const verificationResult = await this.verifyPickerByTag(value);
    this.setState({ isVerified: verificationResult, selectedPicker: value });
  }

  async onAssignPicker(event) {
    event.preventDefault();
    this.setState({loading: true});
    const params = {
      referenceNumber: [ this.props.orderReferenceNumber ],
      pickerTag: [{
          id: this.state.selectedPicker,
          tag: 'others'
      }]
    }
    const api = new API({ url: `/picking-service/v3/deliveryOrders/pick` })
    const result = await api.post(params);
    if (result) {
      this.setState({ loading: false })
      this.props.updateCompleted();
    }
  }

  getAvailablePickers(allPickers, activePickers) {
    activePickers.forEach(active => {
      const foundIndex = allPickers.findIndex(x => x.value.toString() === active.pickerId);
      if (foundIndex !== -1) {
        allPickers.splice(foundIndex, 1);
      }
    })
    return allPickers;
  }

  render() {
    const { Form } = this.components;
    return this.state.loading || !this.props.data ? (
      <Loader />
    ) : (
      <Form className="assign-picker-form" onCancel={this.props.onCancel}>
        {
          this.state.availablePickers && (
            <React.Fragment>
              <div>{getMessage('deliveryOrder.details.action.selectPicker')}</div>
              <Select
                className="active-picker-select"
                name={`active-pickers-search-select`}
                placeholder={'Search by name'}
                options={this.state.availablePickers}
                value={this.state.selectedPicker}
                onChange={this.handleSelectPicker}
                disabled={this.state.isOnlyMkpPacklists}
                testid="active-picker-select"
              />
            </React.Fragment>
          )
        }
        { this.state.selectedPicker && !this.state.isVerified && (
          <div className='error-msg'>{getMessage('deliveryOrder.details.error.manualAssignPicker')}</div>
        )}
        {
          this.state.isOnlyMkpPacklists &&
          <div className='error-msg'>
            <p>{getMessage('deliveryOrder.details.warning.pickerCannotBeAssigned')}</p>
            {getMessage('deliveryOrder.details.warning.blockManualAssign')}
          </div>
        }
        <div className="actions bottom-actions-wrapper">
          <button
            className="button"
            onClick={this.props.onCancel}>
            {getMessage('deliveryOrder.details.action.cancel')}
          </button>
          <button
            className="primary button"
            disabled={!this.state.selectedPicker || !this.state.isVerified || this.state.isOnlyMkpPacklists}
            onClick={e => this.onAssignPicker(e)}>
            {getMessage('deliveryOrder.details.action.assign')}
          </button>
        </div>
      </Form>
    )
  }
}

export default AssignPicker;
