import React from 'react'
import { BaseForm, Select, Radio } from '../../../components/Form'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import Loader from '../../../components/Loader'
import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import MultiTier from './MultiTier'

import './style.css'

class LogisticsConfig extends BaseForm {
  componentDidMount() {
    this.setState({
      loading: true,
    })
    const api = new API({ url: '/account-service/config/logistics' })
    api.get().then(response => {
      const config = response.data.config.logistics
      this.setState({
        loading: false,
        values: config,
      })
    })
  }

  onSubmit(_formData) {
    this.setState({
      submitting: true,
    })
    const data = Object.assign({}, this.state.values)
    if (!data.canLeaveAtDoorstep) {
      data.canLeaveAtDoorstep = 'false'
    }
    if (!data.shouldCaptureOdometer) {
      data.shouldCaptureOdometer = 'false'
    }
    const api = new API({ url: '/account-service/config/logistics' })
    api.put({ logistics: data }).then(
      () => {
        this.props.history.goBack()
      },
      error => {
        this.setState({
          formError: error.message
            .split(':')
            .slice(1)
            .join(':'),
          submitting: false,
        })
      }
    )
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    return (
      <AuthenticatedPage menu={this.props.menu}>
        <h1>{getMessage('logistics.config.heading')}</h1>
        {this.state.formError && (
          <div className="form-error">{this.state.formError}</div>
        )}
        {this.state.loading ? (
          <Loader />
        ) : (
          <Form className="instore-config-form logistics-config-form">
            <Select
              label={getMessage('logistics.config.reseesOrders')}
              name="REseesOrders"
              className="logistic-select"
              placeholder={getMessage('logistics.config.resees.placeholder')}
              {...this.generateStateMappers({
                stateKeys: ['RESeesOrders'],
                loseEmphasisOnFill: true,
              })}
              options={[
                {
                  text: getMessage('logistics.config.oneByone'),
                  value: 'ONE_BY_ONE',
                },
                {
                  text: getMessage('logistics.config.allOrders'),
                  value: 'ALL_ORDERS',
                },
              ]}
            />
            <Radio
              className="logistics-radio"
              label={getMessage('logistics.config.leaveAtDoorstep')}
              name="canLeaveAtDoorstep"
              {...this.generateStateMappers({
                stateKeys: ['canLeaveAtDoorstep'],
                loseEmphasisOnFill: true,
                defaultValue: 'false',
              })}
              options={[
                {
                  text: getMessage('logistics.config.yes'),
                  value: true,
                },
                {
                  text: getMessage('logistics.config.no'),
                  value: 'false',
                },
              ]}
            />
            <Radio
              className="logistics-radio"
              name="shouldCaptureOdometer"
              label={getMessage('logistics.config.shouldCaptureOdometer')}
              {...this.generateStateMappers({
                stateKeys: ['shouldCaptureOdometer'],
                loseEmphasisOnFill: true,
                defaultValue: 'false',
              })}
              options={[
                {
                  text: getMessage('logistics.config.yes'),
                  value: true,
                },
                {
                  text: getMessage('logistics.config.no'),
                  value: 'false',
                },
              ]}
            />
            <div className="form-action">
              <SubmitButton>{getMessage('logistics.save')}</SubmitButton>
              <button
                type="button"
                className="button"
                onClick={this.props.history.goBack}
              >
                {getMessage('logistics.cancel')}
              </button>
            </div>
          </Form>
        )}
        <hr />
        <MultiTier />
      </AuthenticatedPage>
    )
  }
}

export default LogisticsConfig
