import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import Image from '../../../components/Image'
import { Dialog, Popup } from '../../../components/Popup'
import { AttendanceButton } from '../../operations/Pickers'
import customerImagePlaceholder from './customer-image-placeholder.svg'
import {
  getSession,
  isExtensionEnabled,
  hasPermissions,
} from '../../../lib/auth'

import API from '../../../lib/api'
import { getMessage } from '../../../lib/translator'
import { formatTime, getPrintableTime } from '../../../lib/datetime'

import UserShiftsForm from './Form'
import UserShiftsFilter from './Filters'
import ResetPasswordForm from './Form/ResetPassword'

import './style.css'

import emptyIcon from './employeeEmpty.svg'
import { get } from '../../../lib/storage'

const MSG_EMP_ADD = getMessage('employee.add')

const emptyState = {
  icon: emptyIcon,
  message: getMessage('shifts.empty.message'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.ADD)
      }}
    >
      + {MSG_EMP_ADD}
    </button>
  ),
}

const checkIn = (id) => {
  const api = new API({ url: '/account-service/attendance' })
  return api.post({ userId: id }).then(
    (response) => {
      response = response.data.attendance
      return response
    },
    (error) => {
      if (error.code === 401) {
        throw error
      }
      return error
    }
  )
}

const checkOut = (id, userId) => {
  const api = new API({ url: `/account-service/attendance/${id}` })
  return api.put({ userId: userId }).then(
    (response) => {
      response = response.data.attendance
      return response
    },
    (error) => {
      if (error.code === 401) {
        throw error
      }
      return error
    }
  )
}

class TableActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showReset: false,
      showSuccess: false,
    }
  }

  render() {
    const { onAction, id, name } = this.props
    const { showReset, showSuccess } = this.state
    return (
      <React.Fragment>
        {showSuccess && (
          <Dialog
            className="success"
            show={this.state.showSuccess}
            title={getMessage('shifts.reset.success.title')}
            information={getMessage('shifts.reset.success.information')}
            closeText={getMessage('shifts.reset')}
            close={() => this.setState({ showSuccess: false })}
          />
        )}
        {showReset && (
          <Popup
            show={this.state.showReset}
            heading={`${getMessage('shifts.reset.title')} ${name}`}
            close={() => this.setState({ showReset: false })}
          >
            <ResetPasswordForm
              id={id}
              onSuccess={() =>
                this.setState({ showReset: false, showSuccess: true })
              }
              close={() => this.setState({ showReset: false })}
            />
          </Popup>
        )}
        <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
          <DropDownItem onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}>
            {getMessage('shifts.edit')}
          </DropDownItem>
          <DropDownItem onClick={() => this.setState({ showReset: true })}>
            {getMessage('shifts.resetpassword')}
          </DropDownItem>
          {hasPermissions('account', 'employee', 'delete') && (
            <DropDownItem
              onClick={() => onAction(TABLE_ACTIONS.DELETE, { id })}
            >
              {getMessage('shifts.delete')}
            </DropDownItem>
          )}
        </DropDown>
      </React.Fragment>
    )
  }
}

const tableProperties = {
  headers: [
    getMessage('shifts.header.name'),
    getMessage('shifts.header.offdaysAndStores'),
    getMessage('shifts.header.timingsAndAttendance'),
    getMessage('shifts.header.lastLoggedIn'),
    getMessage('shifts.header.blankHeading'),
    getMessage('shifts.header.actions'),
  ],
  row: ({
    id,
    name,
    lastLoggedIn,
    shiftStart,
    shiftEnd,
    imageUrl,
    weeklyOff,
    designation,
    isCheckedIn,
    attendance,
    stores,
    onAction,
  }) => (
    <Row>
      <Cell className="column-user-name">
        <Image src={imageUrl || customerImagePlaceholder} size="sm" />
        <div className="user-details">
          <div
            className="user-name"
            onClick={() => onAction(TABLE_ACTIONS.EDIT, { id })}
          >
            {name}
          </div>
          {designation && designation.name && (
            <small className="text-muted">{designation.name}</small>
          )}
        </div>
      </Cell>
      <Cell className="user-off-days">
        {weeklyOff && weeklyOff.length > 0 ? (
          <div className="weekly-off-days">
            {weeklyOff.map((day) => `${getMessage(day)}`).join(', ')}
          </div>
        ) : (
          <div className="weekly-off-days"> -/- </div>
        )}
        {stores && stores.length > 0 ? (
          <div className="store-affiliation-count">{stores.length}</div>
        ) : (
          <div className="store-affiliation-count">0</div>
        )}
      </Cell>
      <Cell className="user-timings">
        {designation &&
        designation.timingType === 'FIXED' &&
        shiftStart &&
        shiftEnd ? (
          <div className="timings">
            {designation &&
            designation.timingType === 'FIXED' &&
            shiftStart &&
            shiftEnd
              ? `${formatTime(`${shiftStart}`)} - ${formatTime(shiftEnd)}`
              : ''}
          </div>
        ) : (
          <div className="timings">-/-</div>
        )}
        {attendance && attendance.createdAt ? (
          <div className="attendance-timing">
            {getPrintableTime(attendance.createdAt)}
          </div>
        ) : designation ? (
          designation.timingType !== 'FLEXI' ? (
            <div className="attendance-timing">
              {getMessage('attendance.label.notCheckedIn')}
            </div>
          ) : (
            <div className="attendance-timing">-/-</div>
          )
        ) : (
          <div className="attendance-timing">
            {getMessage('attendance.label.noDesignation')}
          </div>
        )}
      </Cell>
      <Cell className="last-logged-in">
        <div>{lastLoggedIn}</div>
      </Cell>
      <Cell className="user-attendance">
        {designation && designation.timingType === 'FIXED' ? (
          <AttendanceButton
            onAction={onAction}
            id={id}
            attendance={attendance}
            isCheckedIn={isCheckedIn}
          />
        ) : (
          ''
        )}
      </Cell>
      <Cell>
        <div className="table-actions">
          <TableActions id={id} onAction={onAction} name={name} />
        </div>
      </Cell>
    </Row>
  ),
}

export default class UserShifts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showFilterAction: false,
    }
    this.updatePickerZones = this.updatePickerZones.bind(this)
  }

  updatePickerZones(params) {
    return new Promise((resolve, reject) => {
      if (params.pickerZones && params.storeIds && params.storeIds.length) {
        const pickerZonesApi = new API({
          url: `/picking-service/v3/pickerZones`,
        })
        const postParams = {
          zoneIds: params.pickerZones,
          storeId: params.storeIds[0],
          pickerId: params.id,
        }
        pickerZonesApi.post(postParams).then(
          () => {
            resolve()
          },
          (error) => {
            reject(error)
          }
        )
      } else {
        resolve()
      }
    })
  }

  componentDidMount() {
    this.api = new API({ url: '/account-service/country' })
    this.api.get().then((response) => {
      const isoCode = response.data.country.isoCode
      this.setState({
        isoCode: isoCode,
      })
    })
    this.designationApi = new API({ url: '/account-service/designation' })
    const user = getSession().user || {}
    this.designationApi.get({ paginated: 'false' }).then((response) => {
      let designations = response.data.designation
      if (
        user.isOwner !== 1 &&
        user.designation &&
        user.designation.designationLevel
      ) {
        designations = designations.filter(
          (designationValue) =>
            designationValue.designationLevel === null ||
            (designationValue.designationLevel &&
              Number(designationValue.designationLevel) <
                Number(user.designation.designationLevel))
        )
      }

      this.setState({
        designations,
      })
    })
    if (isExtensionEnabled('MultiStoreSupport')) {
      const { name } = user.designation

      if (name === 'Superadmin') {
        this.storesAPI = new API({ url: '/account-service/store' })

        this.storesAPI.get({ paginate: 'false' }).then((response) => {
          const allStores = response.data.store.reverse()

          this.setState({
            stores: allStores,
          })
        })
      } else {
        const currentUserStore = JSON.parse(get('stores'))

        this.setState({
          stores: currentUserStore,
        })
      }
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
    this.storesApi && this.storesApi.cancel()
    this.designationApi && this.designationApi.cancel()
  }
  render() {
    const { props } = this
    return (
      <ListingPage
        menu={props.menu}
        modalClassName="shifts-form"
        className="user-shifts-page"
        addHeading={MSG_EMP_ADD}
        editHeading={getMessage('shifts.edit.heading')}
        title={getMessage('shifts.heading')}
        api={{
          url: '/account-service/employee',
          transform: (response) => response.data.employee,
        }}
        form={{
          component: UserShiftsForm,
          allowDelete: true,
          options: {
            multiStoreEnabled: isExtensionEnabled('MultiStoreSupport'),
            stores: this.state && this.state.stores,
            isoCode: this.state && this.state.isoCode,
            designations: this.state && this.state.designations,
          },
          transformSubmit: (formData) => {
            const params = Object.assign({}, formData)
            if (params.phones) {
              params.phone = params.phones[0].phone
              delete params.phones
            }
            if (params.emails) {
              params.email = params.emails[0].email
              delete params.emails
            }
            params.hasToolAccess = 1
            if (params.designationId) {
              const selectedDesignation = this.state.designations.filter(
                (des) => Number(des.id) === Number(params.designationId)
              )[0]
              if (selectedDesignation.timingType === 'FLEXI') {
                delete params.shiftStart
                delete params.shiftEnd
              } else {
                params.shiftStart = params.shiftStart + ':00'
                params.shiftEnd = params.shiftEnd + ':00'
              }
            }
            return params
          },
          transformResponse: (response) => {
            const employee = response.data.employee
            const newEmployee = Object.assign({}, employee)
            if (newEmployee.store.length > 0) {
              newEmployee.storeIds = (newEmployee.store || []).map((store) => ({
                text: store.name,
                value: store.id,
              }))
            }
            return newEmployee
          },
        }}
        headerActions={({ onAction }) => (
          <button
            className="primary button"
            onClick={() => {
              onAction(TABLE_ACTIONS.ADD)
            }}
          >{`+ ${MSG_EMP_ADD}`}</button>
        )}
        emptyState={emptyState}
        tableProperties={tableProperties}
        filters={{
          component: UserShiftsFilter,
          options: {
            multiStoreEnabled: isExtensionEnabled('MultiStoreSupport'),
            stores: this.state && this.state.stores,
            designations: this.state.designations || [],
          },
          transformSubmit: (formData) => {
            const filters = Object.assign({}, formData)
            if (formData.designationId && !formData.designation) {
              this.props.history.push('/hr/employees')
            }
            if (formData.designation) {
              filters.designationId = formData.designation.id
              delete filters.designation
            }
            if (formData.employee) {
              filters.name = formData.employee.name
              delete filters.employee
            }
            return filters
          },
        }}
        updatePickerZones={this.updatePickerZones}
      />
    )
  }
}

export { checkIn, checkOut }
