import React, { Component, useContext } from 'react'
import API from '../../../lib/api'
import ActivePickers from './ActivePickers'
import ActiveZonePickers from './ActiveZonePickers'
import IdlePickers from './IdlePickers'
import TotalPickers from './TotalPickers'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import Loader from '../../../components/Loader'
import { Link } from 'react-router-dom'
import { getMessage } from '../../../lib/translator'
import emptyStateIcon from './svg/picker-empty.svg'
import settingImage from './svg/settings-inactive.svg'
import StoreSelector from '../../../containers/StoreSelector'
import { PICKERS_TODAY_ROLE_IDS } from '../../../config/app'

import './style.css'
import { get } from '../../../lib/storage'
import Toast from '../../../components/Toast'
import { SplitContext } from 'containers/SplitContext'

const EmptyState = () => {
  return (
    <React.Fragment>
      <div className="pickers-empty-state">
        <img src={emptyStateIcon} alt="settings" />
        {getMessage('pickers.empty')}
      </div>
    </React.Fragment>
  )
}

class PickersToday extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pickerLoading: true,
      employeeLoading: true,
      Loading: true,
      storeId: get('store'),
      multiStores: [],
      employees: [],
      pickers: [],
      activeZonePickers: [],
      showUnassignToast: false,
    }
    this.changeStore = this.changeStore.bind(this)
    this.handleUnassignPicker = this.handleUnassignPicker.bind(this)
    this.handleUnassignZonePicker = this.handleUnassignZonePicker.bind(this)
  }

  changeStore(storeId) {
    this.setState({ storeId }, this.getEmployees)
  }

  updateStates(employees) {
    let pickerIds = null
    if (employees.length) {
      pickerIds = employees.map(emp => emp.id)
    }

    this.pickerApi = new API({
      url: '/order-service/picker-activity',
    })

    this.activeZonePickerApi = new API({
      url: `/picking-service/v3/pickers/${this.state.storeId}`,
    })

    this.pickerApi
      .post({
        orgIds: ['2'],
        pickerIds: pickerIds,
      })
      .then(res => {
        this.setState(
          {
            pickers: res.data,
            pickerLoading: false,
            multiStores: JSON.parse(get('stores')),
          },
          () => {
            this.setState({ employees, employeeLoading: false, Loading: false })
          }
        )
      })
      .catch(error => {
        throw error
      })

    this.activeZonePickerApi
      .get()
      .then(res => {
        this.setState(
          {
            activeZonePickers: res.activePickers,
            pickerLoading: false,
            multiStores: JSON.parse(get('stores')),
          },
          () => {
            this.setState({ employees, employeeLoading: false, Loading: false })
          }
        )
      })
      .catch(error => {
        throw error
      })
  }

  getEmployees() {
    this.setState({ Loading: true })
    const roleIds = PICKERS_TODAY_ROLE_IDS.split(",")
    const promises = []

    roleIds.forEach((roleId) => {
      const promise = new Promise((resolve) => {
        roleId = roleId.trim()
        this.employeeApi = new API({ url: '/account-service/employee' })
        this.employeeApi.get({
              roleId: roleId,
              paginated: 'false',
              storeId: this.state.storeId,
            })
          .then((res) => {
            resolve(res)
          })
      })
      promises.push(promise)
    })
    Promise.all(promises).then(
        responses => {
        const employees = []
          responses.forEach(response => {
          const pickers = response.data.employee
          employees.push(...pickers)
        })
        this.updateStates(employees)
        }
      ).catch(error => {
        throw error
      })
  }

  componentWillUnmount() {
    this.employeeApi && this.employeeApi.cancel()
    this.pickerApi && this.pickerApi.cancel()
    this.activeZonePickerApi && this.activeZonePickerApi.cancel()
  }

  componentDidMount() {
    this.getEmployees()
  }

  handleUnassignPicker(pickerData) {
    this.setState({
      loading: true,
    })
    const unassignAPI = new API({
      url: '/order-service/picker-activity/unassign',
    })
    if (pickerData && pickerData.length === 0) {
      throw new Error('Invalid State')
    }
    const ordersAssigned = pickerData.map(data => {
      return {
        referenceNumber: data.currentOrder.referenceNumber,
        processName: data.currentOrder.processName
      }
    })
    unassignAPI
      .post({
        pickerId: pickerData[0].pickerId,
        orders: ordersAssigned,
        batchId: pickerData[0].currentOrder.batchId,
      })
      .then(() => {
        this.setState({
          loading: false,
          showUnassignToast: true,
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          showUnassignToast: false,
        })
        throw error
      })
  }

  handleUnassignZonePicker(pickerData) {
    this.setState({
      loading: true,
    })
    const unassignZonePickerAPI = new API({
      url: '/picking-service/v3/picklists/unassign',
    })
    if (pickerData && pickerData.length === 0) {
      throw new Error('Invalid State')
    }

    unassignZonePickerAPI
      .post({
        pickerId: pickerData[0].pickerId,
        picklistId: pickerData[0].picklistId,
      })
      .then(() => {
        this.setState({
          loading: false,
          showUnassignToast: true,
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          showUnassignToast: false,
        })
        throw error
      })
  }

  render() {
    const {
      pickers,
      employees,
      pickerLoading,
      employeeLoading,
      multiStores,
      storeId,
      Loading,
      activeZonePickers,
    } = this.state
    return (
      <AuthenticatedPage menu={this.props.menu} className="pickers-today">
        {this.state.showUnassignToast && (
          <Toast
            content="Picker unassigned"
            cancelMessage="Ok"
            onClose={() => {
              this.setState({
                showUnassignToast: false,
              })
              this.getEmployees()
            }}
          />
        )}
        <h1 className="pickers-title">
          {getMessage('pickers.today.title')}
          <Link to="/operations/pickers" className="pickers-nav-btn">
            <button className="primary button vendor-btn">
              {' '}
              <img
                src={settingImage}
                className="setting-image"
                alt="settings"
              />{' '}
              {getMessage('pickers.title')}
            </button>
          </Link>
        </h1>
        {!(pickerLoading || employeeLoading) ? (
          employees.length ? (
            Loading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <StoreSelector
                  value={storeId}
                  onChange={this.changeStore}
                  stores={multiStores}
                />
                <TotalPickers {...this.state} />
                {pickers.length !== 0 ? (
                  <ActivePickers
                    {...this.state}
                    onUnassignPicker={this.handleUnassignPicker}
                  />
                ) : null}
                {activeZonePickers.length !== 0 ? (
                  <ActiveZonePickers
                    {...this.state}
                    onUnassignZonePicker={this.handleUnassignZonePicker}
                  />
                ) : null}
                <IdlePickers {...this.state} />
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <StoreSelector
                value={storeId}
                onChange={this.changeStore}
                stores={multiStores}
              />
              {Loading ? <Loader /> : <EmptyState />}
            </React.Fragment>
          )
        ) : (
          <Loader />
        )}
      </AuthenticatedPage>
    )
  }
}

const WrappedPickerToday = (props) => {
  const { splits } = useContext(SplitContext)
  return <PickersToday splits={splits} {...props}/>
}

export default WrappedPickerToday
