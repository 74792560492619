import React from 'react'
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Image,
  View
} from '@react-pdf/renderer'
import moment from 'moment'
import { getNewDisplayAddress } from 'lib/commonlyused'
import { formatTime } from 'lib/datetime'
import fpLogo from 'icons/fp_logo_pdf.png'
import OrderItemsTable from './OrderItemsTable';

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 14,
  },
  greyText: {
    color: '#343434',
    fontSize: 12,
    lineHeight: 1.2
  },
  greyTextSmall: {
    color: '#343434',
    fontSize: 10,
    lineHeight: 1.2
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: 'grey',
    borderStyle: 'solid',
    paddingBottom: 8
  },
  deliveryDetails: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  titleText: {
    fontSize: 12,
    marginBottom: 6,
  },
  labelText: {
    fontSize: 12,
    color: '#708090',
    marginBottom: 2
  },
  section: {
    marginBottom: 5,
    marginTop: 5,
  },
  image: {
    height: 'auto',
    maxWidth: 160,
  },
  labelGroup: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    alignItems: 'center',
    marginTop: 4,
    flexWrap: 'wrap'
  },
  label: {
    backgroundColor: 'lightgrey',
    borderRadius: 4,
    padding: 4,
    fontSize: 10,
    marginRight: 4,
    marginTop: 4
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const getDeliverySlot = (slotStartTime, slotEndTime) => {
  const slot = {
    startTime: moment(slotStartTime).format('HH:mm:ss'),
    endTime: moment(slotEndTime).format('HH:mm:ss'),
  }
  return `${formatTime(slot.startTime)} - ${formatTime(slot.endTime)}`
};

const PackageLabels = ({ pkg, zonePrefix }) => {
  if (!pkg) {
    return <Text style={{...styles.text, ...styles.greyText}}>There is no label.</Text>
  }

  const labels = pkg?.details?.crates || [];

  const groupedLabels = {};
  const prefixToZone = Object.fromEntries(
      Object.entries(zonePrefix).map(([key, value]) => [value, key])
  );

  labels.forEach(item => {
      const prefix = item.split('-')[0];
      const category = prefixToZone[prefix];
      if (!groupedLabels[category]) {
        groupedLabels[category] = [];
      }
      groupedLabels[category].push(item);
  });

  return (
    <>
     {
      Object.keys(groupedLabels).map(key =>
        <View key={key} style={styles.labelGroup}>
          <Text>{key} - </Text>
          {
            groupedLabels[key].map(label =>
              <View key={label} style={styles.label}>
                <Text>{label}</Text>
              </View>
            )
          }
        </View>
      )
     }
    </>
  )
}

// Create Document Component
const B2BDeliveryNotesPdf = ({ deliveryOrders = [], deliverySlotName, zonePrefix }) => {
  return <Document>
      {
        deliveryOrders.map(order => (
        <Page key={order.referenceNumber} size="A4" style={styles.body}>
          <View style={styles.headerSection}>
            <Image
              style={styles.image}
              src={fpLogo}
            />
            <View>
              <Text style={{ ...styles.text, ...styles.greyText }}>
                {order?.metadata?.bulkOrder ? "Bulk" : "FPON"} - Delivery
              </Text>
              <Text style={{ ...styles.text, fontSize: 16 }}>{deliverySlotName}</Text>
            </View>
          </View>

          <Text style={{marginTop: 6}}>Order: {order.referenceNumber}</Text>

          <View style={styles.deliveryDetails}>
            <View>
              <View style={styles.section}>
                <Text style={styles.titleText}>Customer:</Text>
              <Text style={styles.labelText}>{order.deliveryAddress.addresseeName}</Text>
                <Text style={styles.labelText}>{order.deliveryAddress.phoneNumber}</Text>
              </View>

              <View style={styles.section}>
                  <Text style={styles.titleText}>Address:</Text>
                  <Text style={styles.labelText}>{getNewDisplayAddress(order.deliveryAddress, false, false)}</Text>
              </View>
            </View>
            <View>
              <View style={styles.section}>
                  <Text style={styles.titleText}>Delivery:</Text>
                  <Text style={styles.labelText}>
                    {moment(order.slotStartTime).format('DD-MM-YYYY')}
                    ({moment(order.slotStartTime).format('dddd')})
                  </Text>
                  <Text style={styles.labelText}>
                    {getDeliverySlot(order.slotStartTime, order.slotEndTime)}
                  </Text>
              </View>

              <View style={styles.section}>
                  <Text style={styles.titleText}>Need Help?</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.labelText}>
                      www.fairprice.com.sg/help
                    </Text>
                  </View>
              </View>
            </View>
          </View>

          <Text style={{...styles.text, textDecoration: 'underline'}}>Labels</Text>
          <PackageLabels pkg={order?.package} zonePrefix={zonePrefix} />
          <OrderItemsTable order={order} />

          <Text style={{...styles.greyTextSmall, marginTop: 8}}>
            Note:
          </Text>
          <Text style={styles.greyTextSmall}>
            1.  For Home Delivery service, your items will be delivered to your doorstep at your confirmed date and time.
          </Text>

          <Text style={styles.greyTextSmall}>
            2.  If what you have ordered does not match what you have received, please contact us at
          </Text>
          <Text style={{...styles.greyTextSmall, marginLeft: 14}}>
            https://www.fairprice.com.sg/contact-us with your Order ID and a list of affected items. Do note that all issues must be reported to us within 7 days of receipt.
          </Text>

          <Text style={styles.greyTextSmall}>
            3.  Our Customer Service operating hours are from Monday to Sunday: 8:30am to 10pm.
          </Text>

          <Text style={styles.greyTextSmall}>
            4.  For exchanges or refunds up to $200 paid for by credit card only, you may return the affected items at any FairPrice
          </Text>
          <Text style={{...styles.greyTextSmall, marginLeft: 14}}>
            Store (Except FairPrice Shop, FairPrice Express and Cheers). Returned items must be in their original packaging with proof of purchase within 7 days of the date you received them.
          </Text>

          <Text style={styles.greyTextSmall}>
            5.  For more information, visit our Help Centre at https://www.fairprice.com.sg/help
          </Text>

          <Text style={styles.greyTextSmall}>
            6.  With effect from 1 November 2023, the Link Rewards Programme has been updated.
          </Text>
          <Text style={{...styles.greyTextSmall, marginTop: 10 }}>
            This is a computer generated document. No signature is required.
          </Text>
        </Page>
        ))
      }
  	</Document>
}

export default B2BDeliveryNotesPdf;
