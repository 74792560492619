import React, { Component } from 'react'
import ListingPage, { TABLE_ACTIONS } from '../../../containers/ListingPage'
import { Row, Cell } from '../../../components/Table'
import Tabs from '../../../components/Tabs'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import Image from '../../../components/Image'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { getMessage } from '../../../lib/translator'
import editForm from './Form'
// import Filters from './Filter'
import StoreConfigVariables from './StoreConfigForm'
import './style.css'
import EmptyIcon from './empty.svg'
import { ProductSearch, CategorySearch } from '../../../components/Form'

const tableProperties = {
  headers: [
    getMessage('category.header.image'),
    getMessage('category.header.name'),
    getMessage('store.min.stock.storeBulk'),
    getMessage('store.min.stock.count'),
    getMessage('store.max.purchaseable.qty'),
    getMessage('store.actions'),
  ],
  row: ({
    name,
    entityId,
    category,
    product,
    stockBuffer,
    maxPurchasableStock,
    bulkOrderThreshold,
    onAction,
  }) => {
    return (
      <Row>
        <Cell className="store-pdt-image">
          <Image
            size="sm"
            src={
              category
                ? category.image
                : product && product.images && product.images[0]
            }
            bordered
          />
        </Cell>
        <Cell className="name">
          <span
            className="category-name"
            onClick={() => {
              onAction(TABLE_ACTIONS.EDIT, { entityId })
            }}
          >
            {name ? (
              name.name
            ) : category ? (
              category.name
            ) : product ? (
              product.brand ? (
                <React.Fragment>
                  <span className="bold">{product.brand.name} </span>
                  {product.name}
                </React.Fragment>
              ) : (
                product.name
              )
            ) : null}
          </span>
        </Cell>
        <Cell className="store-stock-buffer align-right">
          <span className="store-mobile-header">
            {getMessage('store.min.stock.storeBulk')}
          </span>
          {bulkOrderThreshold}
        </Cell>
        <Cell className="store-stock-buffer align-right">
          <span className="store-mobile-header">
            {getMessage('store.min.stock.count')}
          </span>
          {stockBuffer}
        </Cell>
        <Cell className="store-stock-maxpurchase align-right">
          <span className="store-mobile-header">
            {getMessage('store.max.purchaseable.quantity')}
          </span>
          {maxPurchasableStock}
        </Cell>
        <Cell className="store-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT, { entityId })
              }}
            >
              {getMessage('category.action.edit')}
            </DropDownItem>
            <DropDownItem
              onClick={() => {
                onAction(TABLE_ACTIONS.DELETE, { entityId })
              }}
            >
              {getMessage('category.action.delete')}
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    )
  },
}

// const filterTransform = (formData) => {
//   let data = Object.assign({}, formData)
//   if (data.storeId) {
//     if (!Number(data.storeId)) {
//       data.storeId = ''
//     }
//   }
//   return data
// }

const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('stores.empty.stock'),
  actions: ({ onAction }) => (
    <button
      className="primary button"
      onClick={() => {
        onAction(TABLE_ACTIONS.EDIT)
      }}
    >
      +{' '}
      <span className="text">{getMessage('store.configuraion.add.text')}</span>
    </button>
  ),
}

export class CategoriesListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storeId: props.selectedStoreId,
    }
  }
  componentDidUpdate() {
    const storeId = this.props.selectedStoreId
    this.state.storeId !== storeId && this.setState({ storeId })
  }
  render() {
    const props = this.props
    const params = { type: props.entity.toUpperCase() }
    if (this.state.storeId) {
      params.storeId = Number(this.state.storeId)
    }
    if (this.state.searchProduct) {
      params.entityId = this.state.searchProduct.id
    }
    if (this.state.searchCategory) {
      params.entityId = this.state.searchCategory.id
    }
    return (
      <React.Fragment>
        {props.entity === 'product' && (
          <ProductSearch
            name="name"
            className="search-listing"
            label=" "
            value={this.state.searchProduct}
            onChange={value => this.setState({ searchProduct: value })}
          />
        )}
        {props.entity === 'category' && (
          <CategorySearch
            name="name"
            className="search-listing"
            label=" "
            value={this.state.searchCategory}
            onChange={value => this.setState({ searchCategory: value })}
            withOptions
            placeholder={'Search Category'}
          />
        )}
        <ListingPage
          className="entity-list"
          api={{
            url: '/catalogue-service/stock-override',
            params,
            overWriteDeleteParams: false,
            transform: response => {
              const stockOverride = response.data.stockOverride || []
              const category = response.data.category || []
              const product = response.data.product || []
              if (stockOverride.length > 0) {
                stockOverride.forEach(item => {
                  item.category = category.filter(
                    cat => cat.id === item.entityId
                  )[0]
                  item.product = product.filter(
                    pdt => pdt.id === item.entityId
                  )[0]
                })
              } else {
                stockOverride.category = category[0]
                stockOverride.product = product[0]
              }
              return stockOverride
            },
            updateApiParams: (prevParams, newParams) => {
              if (
                prevParams.entityId !== newParams.entityId ||
                prevParams.type !== newParams.type ||
                prevParams.storeId !== newParams.storeId
              ) {
                return {
                  shouldUpdate: true,
                  params: {
                    type: newParams.type,
                    storeId: newParams.storeId,
                    entityId: newParams.entityId,
                  },
                }
              }
            },
          }}
          emptyState={EmptyState}
          form={{
            component: editForm,
            transformSubmit: formData => {
              const data = Object.assign({}, formData)
              if (this.state.storeId) {
                data.storeId = Number(this.state.storeId)
              }
              if (data.category && data.category.id) {
                data.entityId = data.category.id
                delete data.category
              }
              if (data.product && data.product.id) {
                data.entityId = data.product.id
                delete data.product
              }
              if (data.maxPurchasableStock) {
                data.maxPurchasableStock = Number(data.maxPurchasableStock)
              } else {
                delete data.maxPurchasableStock
              }
              if (data.stockBuffer) {
                data.stockBuffer = Number(data.stockBuffer)
              } else {
                delete data.stockBuffer
              }
              return data
            },
            options: {
              entityName: props.entity,
            },
            allowDelete: true,
            overwriteWithApiParams: false,
          }}
          noId
          primaryKey="id"
          tableProperties={tableProperties}
          addHeading="Add Configuration"
          editHeading="Edit Configuration"
          headerActions={({ onAction }) => (
            <button
              className="primary button"
              onClick={() => {
                onAction(TABLE_ACTIONS.EDIT)
              }}
            >
              +{' '}
              <span className="text">
                {getMessage('store.configuraion.add.text')}
              </span>
            </button>
          )}
        />
      </React.Fragment>
    )
  }
}

const categoryEntity = [
  {
    name: 'Categories',
    component: CategoriesListing,
  },
]
const productEntity = [
  {
    name: 'Products',
    component: CategoriesListing,
  },
]
const entities = productEntity.concat(categoryEntity)

class TabsComponent extends Component {
  render() {
    const {
      activeIndex,
      entities,
      changePage,
      entity,
      selectedStoreId,
    } = this.props
    const ListingComponent = entities[activeIndex].component
    return (
      <div className="tabs-container">
        <Tabs
          items={entities.map(entity => `${entity.name}`)}
          default={activeIndex}
          onClick={index => {
            changePage(index)
          }}
        />
        <div className="tab-contents">
          <ListingComponent entity={entity} selectedStoreId={selectedStoreId} />
        </div>
      </div>
    )
  }
}
class InterfaceContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      entity: 'product',
      storeId: null,
    }
    this.mapping = ['product', 'category']
    this.changePage = this.changePage.bind(this)
    this.saveSelectedStore = this.saveSelectedStore.bind(this)
  }
  changePage(activeIndex) {
    this.setState({
      activeIndex,
      entity: this.mapping[activeIndex],
    })
  }
  saveSelectedStore(storeId) {
    this.setState({ storeId })
  }

  render() {
    return (
      <div className="store-override-wrapper">
        <h1 className="title">Stock Overrides</h1>
        <StoreConfigVariables saveSelectedStore={this.saveSelectedStore} />
        <TabsComponent
          entities={entities}
          activeIndex={this.state.activeIndex}
          changePage={this.changePage}
          entity={this.state.entity}
          selectedStoreId={this.state.storeId}
        />
      </div>
    )
  }
}

export default class StoreConfig extends Component {
  render() {
    return (
      <AuthenticatedPage menu={this.props.menu} className="store-override">
        <InterfaceContainer />
      </AuthenticatedPage>
    )
  }
}
