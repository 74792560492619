import React from 'react'
import { LINK_HOST } from 'config/app'
import API from 'lib/api'
import { formatIsoDate } from 'lib/datetime'
import { paymentErrorMsg } from '../service'
import '../style.css'

const InfoRow = ({title, info, showRow}) => {
  return (
    showRow && (
    <div className='info-row'>
      <div><b>{title}</b></div>
      <div>{info}</div>
    </div>)
  )
}

const parseJwcMembership = (membershipObj, paymentErrMsg) => {
  const endDate = membershipObj.membership.endDate;
  const startDate = membershipObj.membership.startDate;
  const isActive = new Date(endDate).getTime() >= new Date().getTime() &&
    new Date().getTime() >= new Date(startDate).getTime()
  return {
    status: isActive ? 'Active' : 'Inactive',
    packageName: membershipObj.package.title,
    cardNumber: isActive ? membershipObj.membership.cardNumber : null,
    endDate: formatIsoDate(endDate),
    startDate: isActive ? formatIsoDate(membershipObj.membership.startDate) : null,
    renewal: isActive ? membershipObj.membership.isAutoRenewal ? 'ON' : 'OFF' : null,
    paymentError: isActive ? paymentErrMsg : null
  }
}

export const PaymentError = ({ paymentError }) => {
  return (
    <div className="payment-wrapper">
      <div>
        <b>Payment error</b>
      </div>
      <div>{paymentError}</div>
    </div>
  )
}

const Jwc = ({ uid }) => {
  const [membership, setMembership] = React.useState({
    status: null,
    packageName: null,
    cardNumber: null,
    endDate: null,
    startDate: null,
    renewal: null,
    paymentError: null
  })

  const getCustomerJwcApi = new API({
    url: `${LINK_HOST}/laksa/internal/memberships?uid=${uid}&programmeId=1`,
  })

  const getJwc = async () => {
    try {
      const jwcObc = await getCustomerJwcApi.get();
      const latestOrder = jwcObc.orders[0];

      const getPaymentErrMsg = paymentErrorMsg(latestOrder.status, latestOrder.failureReason)
      const parsedMembership = parseJwcMembership(jwcObc, getPaymentErrMsg)

      setMembership(parsedMembership)
    } catch (e) {
      if (e.code === 404 && e.message === 'Requested resource is not found') {
        setMembership({...membership, status: 'Not a member'})
      }
    }
  }

  React.useEffect(() => {
    getJwc();
  }, [])

  return membership && (
    <>
      <div className="tab-wrapper">
        <div className='membership-information'>
          <InfoRow title={'Status'} info={membership.status} showRow={membership.status} />
          <InfoRow title={'Pkg Name'} info={membership.packageName} showRow={membership.packageName} />
          <InfoRow title={'Card Number'} info={membership.cardNumber} showRow={membership.cardNumber} />
          <InfoRow title={'Renewal'} info={membership.renewal} showRow={membership.renewal} />
        </div>
        <div className='date-information'>
          <InfoRow title={'End Date'} info={membership.endDate} showRow={membership.endDate} />
          <InfoRow title={'Start Date'} info={membership.startDate} showRow={membership.startDate} />
        </div>
      </div>
      <PaymentError paymentError={membership.paymentError} />
    </>
  )
}
export default Jwc
