import 'sanitize.css/sanitize.css'
import React, { Component, Fragment, useContext } from 'react'
import NewRouter from './newRouter'
import { withRouter } from 'react-router-dom'
import { getLocale, setLocale } from '../../lib/translator'
import ErrorBoundary from '../../components/ErrorBoundary'
import {
  oktaLogin,
  setToken,
  isOktaLoggedIn,
  subscribeTokenExpiryEvent,
  oktaLogout,
} from '../../lib/okta'

import Login from '../../pages/others/Login'

import { set, get } from '../../lib/storage'
import API from '../../lib/api'
import { GOOGLE_MAP_DEFAULT_KEY, IS_OKTA_ENABLED } from '../../config/app'
import {
  isLoggedIn,
  clearSession,
  saveSession,
  setUrl,
  getUrl,
  setStores,
} from '../../lib/auth'
import { Dialog } from '../../components/Popup'
import _isEmpty from 'lodash.isempty'

import SplitContextProvider, { SplitContext } from '../SplitContext'

import '../../lib/polyfills'
import './app.css'
import { LoadScript } from '@react-google-maps/api'
import { ActionTrailContext } from 'components/ActionTrail'

import Loader from 'components/Loader'

const LIBRARIES = ['places', 'drawing']

export const RouterWrapper = (props) => {
  const { splits, loading } = useContext(SplitContext)

  if (!props.isUserExist || loading || _isEmpty(splits)) {
    return <Loader />
  }

  return <NewRouter {...props} splits={splits} />
}

class App extends Component {
  constructor(props) {
    super(props)

    const urlSearchParams = new URLSearchParams(window.location.search)
    const keys = Object.fromEntries(urlSearchParams.entries())

    if (
      keys.error_description &&
      keys.error_description ===
        'User is not assigned to the client application.'
    ) {
      this.state = {
        loading: true,
        isNotAuth: true,
      }
    } else {
      this.state = {
        loading: IS_OKTA_ENABLED,
        isNotAuth: false,
        userId: '',
      }
      this.handleOktaLogin()
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  async handleOktaLogin() {
    if (IS_OKTA_ENABLED) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const keys = Object.fromEntries(urlSearchParams.entries())
      if (
        !window.location.pathname.includes('/user/callback') &&
        !window.location.pathname.includes('/login')
      ) {
        setUrl(window.location.pathname + window.location.search)
      }
      if (
        window.OktaAuth &&
        window.location.pathname.includes('/user/callback') &&
        keys.code
      ) {
        setToken(
          () => {
            const preserveURL = getUrl()
            this.getRolesAndPermission(preserveURL)
          },
          () => {
            this.setState({ isNotAuth: true })
          }
        )
        return
      }

      const isUserLoggedIn = await isOktaLoggedIn()
      const url = getUrl()
      if (window.OktaAuth && !isUserLoggedIn) {
        oktaLogin(() => {
          this.getRolesAndPermission(url)
        })
      } else {
        this.getRolesAndPermission(url)
      }
    }
  }

  getRolesAndPermission(originalUrl) {
    // Subscribing to token expiry events
    window.OktaAuth && subscribeTokenExpiryEvent()
    this.api = new API({
      url: '/account-service/authorization',
    })
    this.api
      .get()
      .then((response) => {
        delete response.data.user.accessToken
        const { user, organization } = response.data

        saveSession({
          organization,
          user,
        })

        const stores = user.stores.reverse()
        setStores(stores)

        set('store', get('store') || stores[0].id)

        const configApi = new API({
          url: '/config-service/config/backOffice',
        })

        let googleMapApiKey = GOOGLE_MAP_DEFAULT_KEY

        configApi
          .get()
          .then((res) => {
            googleMapApiKey =
              res.data?.backOffice?.googleMapsApiKey || googleMapApiKey
          })
          .catch((error) => {
            if (error.code === 401) {
              throw error
            }
          })
          .finally(() => {
            set('googleMapAPIkey', googleMapApiKey)
            this.setState({ loading: false, userId: user.id })
            this.handleRedirect(response, originalUrl)
          })
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message && e.message.split(':')[1],
        })
      })
  }

  handleRedirect(response, originalUrl) {
    const { history, location } = this.props
    if (originalUrl) {
      history.push(originalUrl)
    } else if (location.state && location.state.from) {
      history.push(location.state.from.pathname)
    } else {
      history.push('/')
    }
    this.props.enterprise &&
      set('organization-name', response.data.organization.name)
  }

  closeDialog() {
    clearSession()
    oktaLogout()
    this.setState({ isNotAuth: false })
  }

  render() {
    const locale = getLocale()
    if (!locale.language) {
      locale.language = 'en'
    }
    setLocale(locale)
    const userId = this.state.userId
    const loginStatus = IS_OKTA_ENABLED ? isOktaLoggedIn() : isLoggedIn()
    return (
      <Fragment>
        {this.state.loading && <Login loading={true} />}
        {this.state.isNotAuth && (
          <Dialog
            className="error"
            show={this.state.isNotAuth}
            close={this.closeDialog}
            message="You're not authorised to access this application. Kindly contact
            admin at dbp-backoffice@fairprice.com.sg"
            closeText="Logout"
          />
        )}
        {!this.state.loading && (
          <ErrorBoundary>
            <SplitContextProvider userKey={userId.toString()}>
              <ActionTrailContext />
              <LoadScript
                googleMapsApiKey={
                  !loginStatus
                    ? ''
                    : get('googleMapAPIkey') || GOOGLE_MAP_DEFAULT_KEY
                }
                libraries={LIBRARIES}
              >
                <RouterWrapper
                  isLoggedIn={loginStatus}
                  isUserExist={!!this.state.userId}
                />
              </LoadScript>
            </SplitContextProvider>
          </ErrorBoundary>
        )}
      </Fragment>
    )
  }
}

export default withRouter(App)
