import AuthenticatedPage from 'containers/AuthenticatedPage'
import { Formik } from 'formik'
import { get } from 'lib/storage'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { Home } from './pages/Home'
import { NewOrder } from './pages/NewOrder'
import { ScanOrder } from './pages/ScanOrder'
import { SelectStore } from './pages/SelectStore'
import { BackButton, View } from './ui'
import { ViewSingleOrder } from './pages/ViewOrder'
import { ViewMultipleOrder } from './pages/ViewMultipleOrder'
import { orders } from './constant'
import { Toaster } from 'react-hot-toast'

const STEPS = {
  STORE_SELECT: 0,
  HOME: 1,
  SCAN_ORDER: 2,
  NEW_ORDER: 3,
  SCAN_BARCODE: 4,
  VIEW_ORDER: 5,
  VIEW_MULTIPLE_ORDER: 6,
}

const testInitialValue =
  process.env.NODE_ENV === 'development'
    ? {
        staffId: '1234',
      }
    : {}

export const PreorderSchema = Yup.object().shape({
  staffId: Yup.string().required('Staff ID is required'),
  customerPhone: Yup.string()
    .matches(new RegExp(/^\d{8}$/i), "Doesn't look like phone number")
    .required('Customer phone is required'),
  customerName: Yup.string()
    .max(40, 'Customer Name cant not exceed 40 characters')
    .matches(
      /* eslint-disable */
      new RegExp(
        /^[a-zA-Z\xc0-\xff]+(?:(?:[a-zA-Z0-9\xc0-\xff ',․\.\-\/])?)+$/i
      ),
      'Customer Name is not valid'
    )
    .required('Customer Name is required'),
  orderPhoneNumber: Yup.string().matches(
    new RegExp(/^\d{8}$/i),
    "Doesn't look like phone number"
  ),
  transactionAmount: Yup.string()
    .test('is-positive-number', 'Invalid Transaction Amount', (value) => {
      const numberValue = parseFloat(value)
      return !isNaN(numberValue) && numberValue > 0 && numberValue <= 99999.99
    })
    .required('Invalid Transaction Amount'), //
})

export const Preorder = ({ menu }) => {
  const [step, setStep] = useState(STEPS.STORE_SELECT)
  const [selectedOrder, setSelectedOrder] = useState(orders[0])
  const selectedStoreId = get('store')
  const stores = JSON.parse(get('stores'))

  const toHome = () => {
    setStep(step !== STEPS.HOME ? STEPS.HOME : STEPS.STORE_SELECT)
  }

  const toScanOrder = () => {
    setStep(STEPS.SCAN_ORDER)
  }

  const toNewOrder = () => {
    setStep(STEPS.NEW_ORDER)
  }

  const toViewOrder = () => {
    setStep(STEPS.VIEW_ORDER)
  }

  const toOrderSummary = () => {
    setStep(STEPS.VIEW_MULTIPLE_ORDER)
  }

  return (
    <AuthenticatedPage menu={menu} title="Pre-order in store">
      <Formik
        initialValues={{
          // store and staff
          staffId: '',
          storeId: selectedStoreId || stores[0].id,
          // customer
          userData: {}, // user ID and user UID
          customerName: '',
          customerPhone: '',
          // campaigns
          selectedCampaign: '', // campaign ID
          selectedCampaignSlug: '',
          // categories
          allCategories: [],
          // product listing pagination
          totalPages: 1,
          currentPage: 1,
          pageSize: 20,
          // pick-up info
          slotId: 0, // time slot ID
          pickupDate: '',
          pickupTime: '',
          // local cart
          allAvailableProducts: [],
          products: {}, // quantity of each product
          cart: {}, // is product checked (checkbox) for checkout
          ...testInitialValue,
        }}
        validateOnMount
        validationSchema={PreorderSchema}
      >
        <View display="block" id="preorderOffline">
          {![STEPS.NEW_ORDER, STEPS.STORE_SELECT].includes(step) ? (
            <BackButton
              width={62}
              height={40}
              onClick={toHome}
              margin="0 0 16px"
            />
          ) : null}
          {step === STEPS.STORE_SELECT && <SelectStore onConfirm={toHome} />}
          {step === STEPS.HOME && (
            <Home onNewOrder={toNewOrder} onScanOrder={toScanOrder} />
          )}
          {step === STEPS.SCAN_ORDER && (
            <ScanOrder
              toHome={toHome}
              toViewOrder={toViewOrder}
              toOrderSummary={toOrderSummary}
            />
          )}
          {step === STEPS.VIEW_ORDER && (
            <ViewSingleOrder toHome={toHome} order={selectedOrder} />
          )}
          {step === STEPS.VIEW_MULTIPLE_ORDER && (
            <ViewMultipleOrder
              toHome={toHome}
              toViewOrder={toViewOrder}
              setSelectedOrder={setSelectedOrder}
            />
          )}
          {step === STEPS.NEW_ORDER && <NewOrder onBackToHome={toHome} />}
        </View>
      </Formik>
      <Toaster position="bottom-center" />
    </AuthenticatedPage>
  )
}
